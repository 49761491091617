import React, { useState, useEffect } from "react";
import axios from "axios";
import { VinSetup } from "@components/RideChallenge";
import { UnverifiedItem } from "./VehicleVerificationView";
import DealerVerification from "./DealerVerification";

export default function VehicleSetup({
  onSuccess,
  fetchData,
  additionalReqData,
  userEffortData,
  verificationCode,
}) {
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [showDealerVerification, setShowDealerVerification] = useState(false);
  const [userSubmitData, setUserSubmitData] = useState(null);

  useEffect(() => {
    if (verificationCode) {
      setDealerCodeData(verificationCode);
    }
  }, []);

  const unverifiedItem = userSubmitData
    ? userEffortData.unverified_data
        .filter((f) => f.vin)
        .find((f) => f.vin.toLowerCase() === userSubmitData.vin.toLowerCase())
    : null;

  if (showDealerVerification) {
    if (dealerCodeData && unverifiedItem) {
      return (
        <div>
          <UnverifiedItem
            info={unverifiedItem}
            additionalReqData={{
              ...additionalReqData,
              verified_remote_code_id: dealerCodeData.id,
            }}
            fetchUser={() => {
              onSuccess();
            }}
            verificationCode={verificationCode}
          />
        </div>
      );
    }

    if (dealerCodeData) return null;

    return (
      <div>
        <DealerVerification
          setDealerCodeData={(e) => {
            setDealerCodeData(e);
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <VinSetup
        isAdditionalVehicle
        onSuccess={(e) => {
          setUserSubmitData(e);
          setShowDealerVerification(true);
          fetchData();
        }}
        additionalReqData={additionalReqData}
        verificationCode={verificationCode}
      />
    </div>
  );
}
