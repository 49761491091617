import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import {
  Title,
  Divider,
  Flex,
  Box,
  Text,
  Button,
  UnstyledButton,
} from "@mantine/core";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import {
  IconWheel,
  IconKey,
  IconUser,
  IconExclamationCircle,
  IconDashboard,
  IconMedal,
} from "@tabler/icons-react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ViewTitle from "./ViewTitle";
import useAppStore from "./ride-challenge-store";
import { LoyaltyMembership } from "@components/LoyaltyMembership";

import VehicleManagement from "./VehicleManagement";
import VehicleSetup from "./VehicleSetup";
import UnverifiedItems from "./UnverifiedItems";
import RideChallengeKeywordForm from "./RideChallengeKeywordForm";
import RevScore from "./RevScore";
import UserMilestones from "./UserMilestones";

import { TieredContestUserProgress } from "@components/Contest";

const views = {
  dashboard: "dashboard",
  membership: "membership",
  add_vehicle: "add_vehicle",
  unverified_data: "unverified_data",
  keyword: "keyword",
  rev_score: "rev_score",
  milestones: "milestones",
};

export default function RideChallengeDashboard() {
  const {
    appConfig,
    vehicleInfo,
    verificationCode,
    setActiveStep,
    coords,
    location,
    effortId,
    setVehicleInfo,
    registrationInfo,
    entityInfo,
    email,
    phone,
    setUser,
    setAppLoading,
    user,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      coords: state.coords,
      effortId: state.effortId,
      email: state.email,
      phone: state.phone,
      entityInfo: state.entityInfo,
      location: state.location,
      registrationInfo: state.registrationInfo,
      vehicleInfo: state.vehicleInfo,
      verificationCode: state.verificationCode,
      user: state.user,
      setUser: state.setUser,
      setActiveStep: state.setActiveStep,
      setAppLoading: state.setAppLoading,
      setVehicleInfo: state.setVehicleInfo,
    }),
    shallow
  );
  const [view, setView] = useState(views.dashboard);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  const foundEffort = user.campaign_efforts.find((f) => f.id === effortId);

  function fetchData() {
    const req = {};

    const locationId = entityInfo.location_id || location ? location.id : null;

    if (locationId) req.location_id = locationId;
    if (effortId) req.campaign_effort_id = effortId;

    if (email) req.email = email;
    if (phone) req.mobile_phone = phone;

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    setAppLoading(true);

    axios
      .post(`/ride-challenge/user-lookup/`, req)
      .then(({ data }) => {
        const usr = data.response[0];

        setUser(usr);

        const foundEffort = usr?.campaign_efforts.find(
          (f) => f.id === effortId
        );

        if (foundEffort) {
          setAppLoading(false);
          if (!foundEffort.registered) return;

          if (foundEffort.registered && !foundEffort.entered) {
            if (foundEffort.has_unverified_readings) {
              return setActiveStep("dashboard");
            } else {
              return setActiveStep("vehicle");
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setAppLoading(false);
        // setUser(null);
      });
  }

  if (view === views.milestones) {
    return (
      <div>
        <AnimatedWrapper>
          <ViewTitle title={`Milestones`} />
          <UserMilestones
            setAppLoading={(val) => setAppLoading(val)}
            reqData={{
              campaign_effort_id: effortId,
              user_id: user.id,
            }}
          />
          <DashboardButton onClick={() => setView(views.dashboard)} />
        </AnimatedWrapper>
      </div>
    );
  }

  if (view === views.keyword) {
    return (
      <div>
        <AnimatedWrapper>
          <Flex justify="center" mb="sm">
            <IconKey
              size={48}
              color="#fff"
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex>
          <ViewTitle title={`Enter Keyword`} />
          <RideChallengeKeywordForm effortId={effortId} onSuccess={fetchData} />
          <DashboardButton onClick={() => setView(views.dashboard)} />
        </AnimatedWrapper>
      </div>
    );
  }

  if (view === views.rev_score) {
    return (
      <div>
        <RevScore
          reqData={{
            user_id: user.id,
            campaign_effort_id: foundEffort.id,
          }}
          effortData={foundEffort}
          expanded
          setAppLoading={(val) => setAppLoading(val)}
          tiers={foundEffort.contests[0].results[0].tier_results}
        />
        <DashboardButton onClick={() => setView(views.dashboard)} />
      </div>
    );
  }

  if (view === views.add_vehicle) {
    return (
      <div>
        <AnimatedWrapper>
          <Flex justify="center" mb="sm">
            <IconWheel
              size={48}
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex>
          <ViewTitle title={`Add Vehicle`} />
          <VehicleSetup
            fetchData={fetchData}
            onSuccess={() => {
              fetchData();
              setView(views.dashboard);
            }}
            additionalReqData={{
              ...coords,
              campaign_effort_id: effortId,
              location_id: entityInfo.location?.id,
              user_interaction_id:
                registrationInfo?.initial_user_interaction_id,
              user_id: user?.id,
              user_location_id: user?.user_location_id,
            }}
            userEffortData={foundEffort}
            verificationCode={verificationCode}
          />
          <DashboardButton onClick={() => setView(views.dashboard)} />
        </AnimatedWrapper>
      </div>
    );
  }

  if (view === views.membership) {
    return (
      <div>
        <AnimatedWrapper>
          <Flex justify="center" mb="sm">
            <IconUser
              size={48}
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex>
          <ViewTitle title={`Membership`} />
          <LoyaltyMembership
            membership={user.hog_membership_data}
            additionalReqData={{
              check: true,
              user_id: user.id,
              user_location_id: user.user_location_id,
              variety: 1,
              campaign_effort_id: effortId,
            }}
            fetchData={fetchData}
          />
          <DashboardButton onClick={() => setView(views.dashboard)} />
        </AnimatedWrapper>
      </div>
    );
  }

  if (view === views.unverified_data) {
    return (
      <div>
        <AnimatedWrapper>
          <Flex justify="center" mb="sm">
            <IconExclamationCircle
              size={48}
              style={{
                stroke: "var(--mantine-color-red-7)",
              }}
            />
          </Flex>
          <ViewTitle title={`These items need attention`} />
          <Box>
            <UnverifiedItems
              additionalReqData={{
                ...coords,
                campaign_effort_id: effortId,
                location_id: entityInfo.location?.id,
              }}
              items={foundEffort.unverified_data.map((m) => ({
                ...m,
                user_vehicle: foundEffort.user_vehicles.find(
                  (f) => f.id === m.user_vehicle_id
                ),
              }))}
              fetchData={fetchData}
              verificationCode={verificationCode}
            />
          </Box>
          <DashboardButton onClick={() => setView(views.dashboard)} />
        </AnimatedWrapper>
      </div>
    );
  }

  return (
    <div>
      {/* <ViewTitle
        title={foundEffort.title}
        subtitle="View progress, log miles"
      /> */}
      <Title order={2} align="center" c="white" fw={600} mt="lg">
        {foundEffort.title}
      </Title>
      <Box mb="lg">
        {/* {foundEffort && (
          <Flex justify="center" direction="column" align="center">
            <Odometer newValue={foundEffort.odometer_total} />
            <Text size="xs" c="dimmed">
              miles logged
            </Text>
          </Flex>
        )} */}
        {foundEffort && (
          <RevScore
            reqData={{
              user_id: user.id,
              campaign_effort_id: foundEffort.id,
            }}
            effortData={foundEffort}
            setAppLoading={(val) => setAppLoading(val)}
            onRevScoreClick={() => setView(views.rev_score)}
            tiers={foundEffort.contests[0].results[0].tier_results}
            revScore={foundEffort.rev_score}
          />
        )}
      </Box>
      {foundEffort.unverified_data.length > 0 && (
        <Flex justify="center" mb="lg">
          <Button
            onClick={() => setView(views.unverified_data)}
            color="red"
            radius="xl"
            size="xs"
            leftSection={<IconExclamationCircle size={16} />}
          >
            Needs Attention
          </Button>
        </Flex>
      )}
      <Divider mt="lg" mb="lg" opacity={0.08} />
      {foundEffort.contests.map((m) => (
        <div key={m.id}>
          <TieredContestUserProgress contestInfo={m} />
        </div>
      ))}
      <Divider mt="lg" mb="lg" opacity={0.08} />
      <VehicleManagement
        fetchData={fetchData}
        verificationCode={verificationCode}
        vehicles={foundEffort?.user_vehicles}
        additionalReqData={{
          ...coords,
          campaign_effort_id: effortId,
          location_id: entityInfo.location?.id,
          user_id: user.id,
        }}
      />
      <Divider mt="lg" mb="lg" opacity={0.08} />
      <Flex justify="center" mt="xl" gap="xl">
        <UnstyledButton onClick={() => setView(views.add_vehicle)}>
          <Flex direction="column" align="center" gap="xs">
            <IconWheel
              size={36}
              color="#fff"
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
            <Text fw={600} c="white" size="xs">
              Add vehicle
            </Text>
          </Flex>
        </UnstyledButton>
        <UnstyledButton onClick={() => setView(views.keyword)}>
          <Flex direction="column" align="center" gap="xs">
            <IconKey
              size={36}
              color="#fff"
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
            <Text fw={600} c="white" size="xs">
              Enter Keyword
            </Text>
          </Flex>
        </UnstyledButton>
        <UnstyledButton onClick={() => setView(views.membership)}>
          <Flex direction="column" align="center" gap="xs">
            <IconUser
              size={36}
              color="#fff"
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
            <Text fw={600} c="white" size="xs">
              Manage membership
            </Text>
          </Flex>
        </UnstyledButton>
        <UnstyledButton onClick={() => setView(views.milestones)}>
          <Flex direction="column" align="center" gap="xs">
            <IconMedal
              size={36}
              color="#fff"
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
            <Text fw={600} c="white" size="xs">
              Milestones
            </Text>
          </Flex>
        </UnstyledButton>
      </Flex>
    </div>
  );
}

const AnimatedWrapper = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0 }}
    // style={box}
    key="box"
  >
    {children}
  </motion.div>
);

const DashboardButton = ({ onClick }) => {
  return (
    <Flex justify="center" mt="xl">
      <Button onClick={onClick} variant="subtle" color="gray" size="xs">
        back to dashboard
      </Button>
    </Flex>
  );
};
