import { create } from "zustand";
import { defaultEmailStreams } from "@components/Email/helpers";

const initialState = {
  activeStep: "registration",
  appConfig: null,
  appLoading: false,
  campaignId: null,
  colors: {
    background: "#000",
    icon: "#FFF",
    card: "#000",
  },
  coords: null,
  coordsNoLocation: false,
  effort: null,
  effortId: null,
  email: "",
  emailStreams: defaultEmailStreams.map((m) => ({
    ...m,
    value: true,
    id: m.variety,
  })),
  entityInfo: null,
  location: null,
  phone: "",
  effortEntryVisible: false,
  membershipInfo: null,
  registrationInfo: null,
  user: null,
  userSelectedEffortId: null,
  vehicleInfo: null,
  verificationCode: null,
  verificationMethod: null,
  zip: "",
};

const useAppStore = create((set) => ({
  ...initialState,
  reset: () => set(initialState),
  setAppConfig: (appConfig) => set(() => ({ appConfig })),
  setAppLoading: (appLoading) => set(() => ({ appLoading })),
  setActiveStep: (activeStep) => set(() => ({ activeStep })),
  setCampaignId: (campaignId) => set(() => ({ campaignId })),
  setColors: (colors) => set(() => ({ colors })),
  setCoords: (coords) => set(() => ({ coords })),
  setCoordsNoLocation: (coordsNoLocation) => set(() => ({ coordsNoLocation })),
  setEffort: (effort) => set(() => ({ effort })),
  setEffortId: (effortId) => set(() => ({ effortId })),
  setEmail: (email) => set(() => ({ email })),
  setEmailStreams: (emailStreams) => set(() => ({ emailStreams })),
  setEntityInfo: (entityInfo) => set(() => ({ entityInfo })),
  setLocation: (location) => set(() => ({ location })),
  setPhone: (phone) => set(() => ({ phone })),
  setMembershipInfo: (membershipInfo) => set(() => ({ membershipInfo })),
  setRegistrationInfo: (registrationInfo) => set(() => ({ registrationInfo })),
  setVehicleInfo: (vehicleInfo) => set(() => ({ vehicleInfo })),
  setUser: (user) => set(() => ({ user })),
  setZip: (zip) => set(() => ({ zip })),
  setVerificationCode: (verificationCode) => set(() => ({ verificationCode })),
  setVerificationMethod: (verificationMethod) =>
    set(() => ({ verificationMethod })),
  setEffortEntryVisible: (effortEntryVisible) =>
    set(() => ({ effortEntryVisible })),
  setUserSelectedEffortId: (userSelectedEffortId) =>
    set(() => ({ userSelectedEffortId })),
}));

export default useAppStore;

// import useAppStore from "@store";
// const user = useAppStore((state) => state.user);

// import { shallow } from "zustand/shallow";
// import useAppStore from "./microsite-store";

// const { admin, setAdmin } = useAppStore(
//   (state) => ({
//     admin: state.admin,
//     setAdmin: state.setAdmin,
//   }),
//   shallow
// );
