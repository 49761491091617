import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Center,
  Select,
  Table,
  ActionIcon,
  Modal,
  Flex,
  Pagination,
  SegmentedControl,
  rem,
  LoadingOverlay,
} from "@mantine/core";
import {
  IconCopy,
  IconQrcode,
  IconList,
  IconSearch,
} from "@tabler/icons-react";
import toast from "react-hot-toast";

import { QrCodeGenerator } from "@components/shared";
import { rideChallengePath } from "@components/RideChallenge/helpers";

const pageSize = 25;

export default function EffortLocationJoinList({
  effortId,
  isRideChallenge = false,
  locationId = null,
}) {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("search");
  const [codes, setCodes] = useState([]);
  const [selected, setSelected] = useState("");
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(codes.slice(0, pageSize));
  const pageCount = Math.round(codes.length / pageSize);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(codes.slice(from, to));
  }, [page, JSON.stringify(codes)]);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/efforts/${effortId}/get-url-codes-for-qr/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        const res = data.response;
        if (locationId) {
          const matchedLocation = res.find((f) => f.id === locationId);
          if (matchedLocation) {
            setSelected(matchedLocation.code);
          }
        }
        setCodes(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setCodes([]);
        setLoading(false);
      });
  }

  const options = codes.map((code) => ({
    value: code.code,
    label: code.name,
  }));

  const codeUrl = !selected
    ? null
    : isRideChallenge
    ? `${rideChallengePath}/${selected}`
    : `${import.meta.env.VITE_DOMAIN}/entry/${selected}`;

  return (
    <div>
      <div>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 1 }}
        />
      </div>
      {!locationId && (
        <SegmentedControl
          mb="lg"
          size="sm"
          value={mode}
          onChange={(e) => setMode(e)}
          data={[
            {
              label: (
                <Center style={{ gap: 10 }}>
                  <IconSearch style={{ width: rem(16), height: rem(16) }} />
                  <span>Search for a location</span>
                </Center>
              ),
              value: "search",
            },
            {
              label: (
                <Center style={{ gap: 10 }}>
                  <IconList style={{ width: rem(16), height: rem(16) }} />
                  <span>View all locations</span>
                </Center>
              ),
              value: "table",
            },
          ]}
        />
      )}
      {mode === "search" && (
        <>
          {!locationId && (
            <Select
              data={options}
              searchable
              label="Location"
              placeholder="Search..."
              value={selected}
              onChange={(e) => setSelected(e)}
              mb={codeUrl ? "lg" : "0"}
            />
          )}
          {codeUrl && <QrCodeGenerator text={codeUrl} />}
        </>
      )}
      {mode === "table" && (
        <>
          <Table striped highlightOnHover>
            <Table.Tbody>
              {records.map((code) => (
                <Table.Tr key={code.id}>
                  <Table.Td>{code.name}</Table.Td>
                  <Table.Td>
                    <Flex align="center" gap="xs">
                      <span>{`${rideChallengePath}/${code.code}`}</span>
                      <ActionIcon
                        size="sm"
                        color="gray"
                        variant="light"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${rideChallengePath}/${code.code}`
                          );
                          toast.success("Copied!");
                        }}
                      >
                        <IconCopy style={{ width: "70%", height: "70%" }} />
                      </ActionIcon>
                      <CodeModal
                        codeUrl={`${rideChallengePath}/${code.code}`}
                        title={code.name}
                      />
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
          <Pagination
            mt="sm"
            value={page}
            onChange={setPage}
            total={pageCount}
          />
        </>
      )}
    </div>
  );
}

const CodeModal = ({ codeUrl, title }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <ActionIcon
        size="sm"
        color="gray"
        variant="light"
        onClick={() => {
          setOpen(true);
        }}
      >
        <IconQrcode style={{ width: "70%", height: "70%" }} />
      </ActionIcon>
      <Modal title={title} size="lg" opened={isOpen} onClose={onClose}>
        <QrCodeGenerator text={codeUrl} />
      </Modal>
    </>
  );
};
