import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  LoadingOverlay,
  Space,
  Modal,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import WizardAuction from "./WizardAuction";
import WizardPoints from "./WizardPoints";
import WizardPrizePool from "./WizardPrizePool";
import WizardMultiSweepstakes from "./WizardMultiSweepstakes";
import WizardKeyword from "./WizardKeyword";
import WizardAddContestToEffort from "./WizardAddContestToEffort";
import WizardAddContestToOrgEffort from "./WizardAddContestToOrgEffort";
import WizardRideChallenge from "./WizardRideChallenge";
import { OptionList, SectionTitle } from "./WizardComponents";

import { LocationSelect } from "@components/Location";
import { OrganizationSelect } from "@components/Organization";

import { locationVariety } from "@components/Location/helpers";

import useAppStore from "./wizard-store";

const goalValues = {
  points: "points",
  prize_pool: "prize_pool",
  auction: "auction",
  sweepstakes: "sweepstakes",
  multi_sweepstakes: "multi_sweepstakes",
  add_contest_to_my_effort: "add_contest_to_my_effort",
  add_contest_to_organization_effort: "add_contest_to_organization_effort",
  ride_challenge: "ride_challenge",
  keyword: "keyword",
};

import { entryMethods } from "./helpers";

export default function InteractionWizard() {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entityName, setEntityName] = useState("");
  const [showMemberWizardOptions, setShowMemberWizardOptions] = useState(false);
  const theme = useMantineTheme();

  const managerInfo = useSelector((state) => state.manager);
  const { pathname } = useLocation();

  const {
    goal,
    setGoal,
    location,
    setLocation,
    organization,
    reset,
    setAllowCheckin,
    setMemberOnly,
    setMicrosite,
    setOrganization,
    setRegistration,
  } = useAppStore(
    (state) => ({
      goal: state.goal,
      location: state.location,
      organization: state.organization,
      reset: state.reset,
      setAllowCheckin: state.setAllowCheckin,
      setGoal: state.setGoal,
      setLocation: state.setLocation,
      setMemberOnly: state.setMemberOnly,
      setMicrosite: state.setMicrosite,
      setOrganization: state.setOrganization,
      setRegistration: state.setRegistration,
    }),
    shallow
  );

  function onClose() {
    reset();
    setOpen(false);
  }

  useEffect(() => {
    if (!isOpen) return;

    fetchSetup();
  }, [isOpen]);

  useEffect(() => {
    onClose();
  }, [pathname]);

  function fetchSetup() {
    setLoading(true);
    axios
      .get(`/wizard-initialize/`)
      .then(({ data }) => {
        const res = data.response[0];
        setEntityName(res.name);
        if (res.location_id) {
          setLocation({
            ...res,
            id: res.location_id,
          });
          if (res.variety === locationVariety.dealer) {
            configureDealerLocation();
          }
        }
        if (res.organization_id) {
          setOrganization({
            ...res,
            id: res.organization_id,
          });
          setAllowCheckin(false);
        }

        setShowMemberWizardOptions(res.show_member_wizard_options);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError("The wizard has encountered a problem.");
      });
  }

  function configureDealerLocation() {
    // setGoal(goalValues.sweepstakes);
    setRegistration(entryMethods.form);
    setMicrosite(true);
    setMemberOnly(false);
  }

  function onLocationChange(e) {
    if (e.variety === locationVariety.dealer) {
      setLocation(e);
      configureDealerLocation();
    } else {
      setLocation(e);
    }
  }

  if (error) {
    return null;
  }

  // if (loading) {
  //   return <div></div>;
  // }

  return (
    <div>
      <Button radius="xl" size="xs" onClick={() => setOpen(true)}>
        Interaction Wizard
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Box pos="relative">
          <LoadingOverlay visible={loading} />
          {!managerInfo && (
            <React.Fragment>
              <SectionTitle text="Select an organization or location" />
              <OrganizationSelect
                disabled={location ? true : false}
                value={organization ? organization.id : ""}
                objectOnChange
                onChange={(e) => {
                  if (e === null) {
                    setOrganization(null);
                  } else {
                    setOrganization(e);
                    setShowMemberWizardOptions(e.show_member_wizard_options);
                  }
                  setLocation(null);
                }}
              />
              <Divider mt="sm" label="OR" />
              <LocationSelect
                value={location ? location.id : ""}
                disabled={organization ? true : false}
                objectOnChange
                onChange={(e) => {
                  if (e === null) {
                    setLocation(null);
                  } else {
                    onLocationChange(e);
                    setShowMemberWizardOptions(e.show_member_wizard_options);
                  }
                  setOrganization(null);
                }}
              />
            </React.Fragment>
          )}
          {(location || organization) && (
            <React.Fragment>
              <Space mt="lg" />
              {/* {((location && location.variety !== locationVariety.dealer) ||
                organization) && ( */}
              {true && (
                <React.Fragment>
                  <Title order={4}>I want to...</Title>
                  <OptionList
                    options={[
                      {
                        label: "Give away points for interactions",
                        value: goalValues.points,
                        tooltip:
                          "For members and/or non-members; Points awarded for check-ins, survey responses or keywords submitted.",
                      },
                      {
                        label: "Create a prize pool",
                        value: goalValues.prize_pool,
                        tooltip: "Prize pools are for members only.",
                      },
                      {
                        label: "Create an auction",
                        value: goalValues.auction,
                        tooltip: "Auctions are for members only.",
                      },
                      {
                        // label: "Create a multi-sweepstakes effort",
                        label: "Create a sweepstakes",
                        value: goalValues.multi_sweepstakes,
                        tooltip:
                          "Create your own standalone sweepstakes. A sweepstakes can have one or more prizes attached to it.",
                      },
                      {
                        label: `Add contest to an existing ${entityName} effort`,
                        value: goalValues.add_contest_to_my_effort,
                        tooltip:
                          "Use this option to add a contest to an existing sweepstakes you've already created.",
                      },
                      {
                        label: `Create a keyword`,
                        value: goalValues.keyword,
                      },
                      {
                        label: `Link a contest to an organization's effort`,
                        value: goalValues.add_contest_to_organization_effort,
                        tooltip:
                          "Use this option to attach a contest to a larger, organizational sweepstakes campaign.",
                      },
                      {
                        label: "Create a ride challenge",
                        value: goalValues.ride_challenge,
                      },
                      // {
                      //   label: `Link a contest to an organization's effort`,
                      //   value: goalValues.add_contest_to_organization_effort,
                      //   tooltip:
                      //     "Use this option to attach a contest to a larger, organizational sweepstakes campaign.",
                      // },
                    ]
                      .filter((f) =>
                        !managerInfo
                          ? ![goalValues.add_contest_to_my_effort].includes(
                              f.value
                            )
                          : true
                      )
                      .filter((f) =>
                        (location &&
                          location.variety === locationVariety.dealer) ||
                        !showMemberWizardOptions
                          ? ![
                              goalValues.auction,
                              goalValues.prize_pool,
                              goalValues.points,
                            ].includes(f.value)
                          : true
                      )
                      .filter((f) =>
                        !managerInfo || organization
                          ? ![
                              goalValues.add_contest_to_organization_effort,
                            ].includes(f.value)
                          : true
                      )
                      .map((m) => ({
                        ...m,
                        active: m.value === goal,
                        onChange: () => setGoal(m.value),
                      }))}
                  />
                  <Space mt="lg" />
                </React.Fragment>
              )}
              {goal === goalValues.points && <WizardPoints />}
              {goal === goalValues.prize_pool && <WizardPrizePool />}
              {goal === goalValues.auction && <WizardAuction />}
              {goal === goalValues.multi_sweepstakes && (
                <WizardMultiSweepstakes />
              )}
              {goal === goalValues.add_contest_to_my_effort && (
                <WizardAddContestToEffort
                  locationId={location ? location.id : null}
                  organizationId={organization ? organization.id : null}
                />
              )}
              {goal === goalValues.add_contest_to_organization_effort && (
                <WizardAddContestToOrgEffort
                  locationId={location ? location.id : null}
                  organizationId={organization ? organization.id : null}
                />
              )}
              {goal === goalValues.ride_challenge && (
                <WizardRideChallenge
                  locationId={location ? location.id : null}
                  organizationId={organization ? organization.id : null}
                />
              )}
              {goal === goalValues.keyword && (
                <WizardKeyword
                  locationId={location ? location.id : null}
                  organizationId={organization ? organization.id : null}
                  isWizard
                />
              )}
            </React.Fragment>
          )}
        </Box>
      </Modal>
    </div>
  );
}
