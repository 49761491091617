import React, { useState } from "react";
import { Card, Group, Tabs, Text, Title, Space } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import {
  useParams,
  useSearchParams,
  Route,
  Routes,
  Link,
} from "react-router-dom";

import { EffortLocationJoinList } from "@components/Effort";
import { KeywordManagement } from "@components/Keyword";
import { ContestItem, ContestList } from "@components/Contest";
import { QrCodeGenerator, Subnav } from "@components/shared";
import { RideChallengeManagement } from "@components/RideChallenge";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

const SectionTitle = ({ children }) => (
  <Title order={4} mb="sm">
    {children}
  </Title>
);

export default function EffortReadOnly({ effortInfo }) {
  const [refresh, setRefresh] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  const isRideChallenge = effortInfo.variety === 6;
  const hasWizardSettings = JSON.stringify(effortInfo.wizard_settings) !== "{}";
  const showQr =
    !hasWizardSettings ||
    (effortInfo.wizard_settings &&
      effortInfo.wizard_settings.microsite === true);
  const showKeyword =
    !hasWizardSettings ||
    (effortInfo.wizard_settings &&
      effortInfo.wizard_settings.show_keywords === true);

  const ContestAddOnly = () => (
    <ContestList
      isManager
      readOnly
      refresh={refresh}
      showVarietyFilters={effortInfo.variety !== 5}
      requestInfo={{
        url: `/retrieve-contests/`,
        data: {
          campaign_effort_id: effortInfo.id,
        },
      }}
      displayFormatter={(contest) => (
        <ContestItem key={contest.id} contestInfo={contest} readOnly />
      )}
    />
  );

  return (
    <div>
      <Subnav
        buttonColor={entityColor.effort}
        links={[
          { text: "Details", to: `/efforts/${effortInfo.id}` },
          { text: "Contests", to: `/efforts/${effortInfo.id}/contests` },
          { text: "Keywords", to: `/efforts/${effortInfo.id}/keywords` },
          {
            text: "Management",
            to: `/efforts/${effortInfo.id}/ride-challenge`,
          },
        ]
          .filter((f) =>
            !isRideChallenge ? !["Management"].includes(f.text) : true
          )
          .filter((f) =>
            !showKeyword ? !["Keywords"].includes(f.text) : true
          )}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Card>
                {effortInfo.start_date_formatted &&
                  effortInfo.end_date_formatted && (
                    <React.Fragment>
                      <SectionTitle>Dates</SectionTitle>
                      <Group spacing="xs">
                        {entityIcon.calendar(24)}
                        <Text>
                          {effortInfo.start_date_formatted} -{" "}
                          {effortInfo.end_date_formatted}
                        </Text>
                      </Group>
                    </React.Fragment>
                  )}
                {showQr && (
                  <React.Fragment>
                    <Space mb="xl" />
                    <SectionTitle>QR Code</SectionTitle>
                    <QrCodeGenerator
                      text={`${import.meta.env.VITE_DOMAIN}/entry/${
                        effortInfo.microsite_url
                      }`}
                    />
                  </React.Fragment>
                )}
              </Card>
              {isRideChallenge && (
                <Card mt="lg">
                  <EffortLocationJoinList
                    effortId={effortInfo.id}
                    locationId={managerInfo.location_id}
                    isRideChallenge
                  />
                </Card>
              )}
            </>
          }
        />
        <Route
          path="/contests"
          element={
            <>
              <ContestAddOnly />
            </>
          }
        />
        {showKeyword && (
          <Route
            path="/keywords"
            element={
              <>
                <KeywordManagement
                  minStartDate={effortInfo.start_date}
                  maxEndDate={effortInfo.end_date}
                  effortId={effortInfo.id}
                  showCreate={false}
                />
              </>
            }
          />
        )}
        {isRideChallenge && (
          <Route
            path="/ride-challenge"
            element={
              <>
                <RideChallengeManagement
                  effortId={effortInfo.id}
                  locationId={managerInfo.location_id}
                />
              </>
            }
          />
        )}
      </Routes>
    </div>
  );
}
