import React from "react";
import { shallow } from "zustand/shallow";
import { Box } from "@mantine/core";

import { OtherButton } from "./UserLocation";
import ViewTitle from "./ViewTitle";
import { LoyaltyMembership } from "@components/LoyaltyMembership";

import useAppStore from "./ride-challenge-store";

export default function MembershipView() {
  const { effortId, membershipInfo, setActiveStep, setMembershipInfo, user } =
    useAppStore(
      (state) => ({
        effortId: state.effortId,
        membershipInfo: state.membershipInfo,
        setActiveStep: state.setActiveStep,
        setMembershipInfo: state.setMembershipInfo,
        user: state.user,
      }),
      shallow
    );

  return (
    <div>
      <ViewTitle title={"Membership"} subtitle={`Do membership stuff here`} />
      {user && (
        <LoyaltyMembership
          additionalReqData={{
            check: true,
            user_id: user.id,
            user_location_id: user.user_location_id,
            variety: 1,
            campaign_effort_id: effortId,
            dashboard: false,
          }}
          setMembershipInfo={(e) => setMembershipInfo(e)}
        />
      )}
      <OtherButton
        onClick={() => setActiveStep("vehicle")}
        text="Skip for now"
      />
    </div>
  );
}
