import React from "react";
import { GaugeComponent } from "react-gauge-component";

export default function RideChallengeRevScoreGauge({
  tiers = [],
  showPointer = true,
  value,
}) {
  if (!tiers.length) return null;
  const formattedTiers = tiers
    .sort((a, b) => a.position - b.position)
    .filter((f) => f.start_value !== undefined && f.end_value !== undefined)
    .map((m) => ({
      id: m.id,
      name: m.name,
      position: m.position,
      start_value: m.start_value,
      end_value: m.end_value + 1,
      color:
        m.settings && m.settings.fill_color ? m.settings.fill_color : "#000",
    }));

  const colors = formattedTiers.map((m) => m.color);
  const subArcs = formattedTiers.map((m) => ({ limit: m.end_value }));
  const maxValue = formattedTiers[formattedTiers.length - 1].end_value;

  return (
    <GaugeComponent
      type="radial"
      style={{
        padding: "0px",
        width: "auto",
      }}
      arc={{
        colorArray: colors,
        // colorArray: ["#b9b9b9", "#fbbf24", "#ebebeb", "#b9f2ff", "#fa6600"],
        padding: 0.02,
        width: 0.15,
        subArcs,
        // subArcs: [
        //   { limit: 2500 },
        //   { limit: 4000 },
        //   { limit: 5500 },
        //   { limit: 7500 },
        //   { limit: 9000 },
        // ],
      }}
      // maxValue={9000}
      maxValue={maxValue}
      labels={{
        valueLabel: {
          hide: true,
        },
        tickLabels: {
          hideMinMax: true,
          type: "outer",
          defaultTickValueConfig: {
            style: {
              fontSize: "10px",
              fill: "#fff",
              wordBreak: "break-word",
            },
            hide: true,
          },
          defaultTickLineConfig: {
            distanceFromArc: 2,
            hide: false,
            color: "transparent",
          },
          // ticks: [
          //   {
          //     value: 1250,
          //     valueConfig: {
          //       formatTextValue: () => "Silver",
          //     },
          //   },
          //   {
          //     value: 3250,
          //     valueConfig: {
          //       formatTextValue: () => "Gold",
          //     },
          //   },
          //   {
          //     value: 4750,
          //     valueConfig: {
          //       formatTextValue: () => "Platinum",
          //     },
          //   },
          //   {
          //     value: 6500,
          //     valueConfig: {
          //       formatTextValue: () => "Diamond",
          //     },
          //   },
          //   {
          //     value: 8250,
          //     valueConfig: {
          //       formatTextValue: () => `Black+Orange`,
          //     },
          //   },
          // ],
        },
      }}
      pointer={
        showPointer
          ? { type: "blob", animationDelay: 0, strokeWidth: 4, length: 0.8 }
          : {
              hide: true,
            }
      }
      value={value}
    />
  );
}

// silver #b9b9b9
// gold #fbbf24
// platinum #ebebeb
// diamond #ebebeb
// black #ebebeb
