import React, { useState, useEffect } from "react";
import { Box, Button, Modal } from "@mantine/core";

import { MilestoneForm, MilestoneList } from "./";
import { Header, ManagementLayout } from "@components/shared";

export default function MilestoneManagement({ effortId }) {
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const reqData = {
    campaign_effort_id: effortId,
  };

  return (
    <div>
      <MilestoneModal
        reqData={reqData}
        onSuccess={() => setRefresh(!refresh)}
      />
      <Box mt="lg">
        <MilestoneList
          refresh={refresh}
          requestInfo={{
            url: `/retrieve-milestones/`,
            data: reqData,
          }}
        />
      </Box>
    </div>
  );
}

const MilestoneModal = ({ reqData, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)} radius="xl" size="xs">
        Create Milestone
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <MilestoneForm reqData={reqData} onSuccess={onClose} />
      </Modal>
    </div>
  );
};
