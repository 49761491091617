import React, { useState } from "react";
import { TextInput, Modal, Text, Flex, Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { TicketEntryForm } from "@components/Ticket";

export default function LoyaltyMembershipForm({
  setMembershipInfo,
  additionalReqData,
  userInfo = null,
}) {
  const [value, setValue] = useState("");
  const [response, setResponse] = useState(null);
  const [inUse, setInUse] = useState(false);
  const [loading, setLoading] = useState(false);

  const adminInfo = useSelector((state) => state.admin);
  const managerInfo = useSelector((state) => state.manager);

  function checkUsage() {
    const req = {
      ...additionalReqData,
      loyalty_membership_ident: value,
    };

    axios
      .post(`/loyalty-memberships/`, req)
      .then(({ data }) => {
        setLoading(false);
        const res = data.response[0];
        if (res.in_use) {
          setInUse(true);
        } else {
          setMembershipInfo(res);
          // setValue("");
          if (!res.verified || !res.active) {
            setResponse(res);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onCodeSubmit() {
    setLoading(true);
    if (additionalReqData.in_use) {
      return checkUsage();
    }
    const req = {
      ...additionalReqData,
      loyalty_membership_ident: value,
    };

    axios
      .post(`/loyalty-memberships/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        setMembershipInfo(res);
        setLoading(false);
        // setValue("");
        if (!res.verified || !res.active) {
          setResponse(res);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onOverrideSubmit() {
    const req = {
      ...additionalReqData,
      loyalty_membership_ident: value,
    };

    setLoading(true);

    axios
      .post(`/loyalty-memberships/override-membership/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        setLoading(false);
        setInUse(false);
        setMembershipInfo(res);
        // setValue("");
        if (!res.verified || !res.active) {
          setResponse(res);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (inUse) {
    if (managerInfo) {
      const issueDescription = `The HOG membeship number ${value} has been confirmed to belong to ${
        userInfo.full_name
      }, Phone: ${userInfo.mobile_phone || "-"}, Email: ${
        userInfo.email || "-"
      }. Follow the link to re-assign this membership.`;

      return (
        <div>
          <Text align="center" c="white">
            This membership number is in use by another user. Please verify it
            belongs to this user. If it does, please create a trouble ticket
            below and we'll take care of it.
          </Text>
          <Flex mt="sm" gap="sm" justify="center">
            <OpenTroubleTicket
              reqData={{
                issue_description: issueDescription,
                campaign_effort_id: additionalReqData.campaign_effort_id,
                // user_id: additionalReqData.user_id,
                first_name: managerInfo.full_name.split(" ")[0],
                last_name: managerInfo.full_name.split(" ")[1],
                email: managerInfo.email,
                url: window.location.href,
              }}
              onSuccess={() => {
                setInUse(false);
                setValue("");
              }}
            />
            <Button
              variant="light"
              color="gray"
              onClick={() => setInUse(false)}
            >
              Back
            </Button>
          </Flex>
        </div>
      );
    }

    return (
      <div>
        <Text align="center" c="red" fw={600}>
          This membership ({value}) is in use. Do you wish to assign this
          membership to this user?
        </Text>
        <Flex mt="sm" gap="sm" justify="center">
          <Button loading={loading} onClick={onOverrideSubmit}>
            Yes
          </Button>
          <Button color="red" onClick={() => setInUse(false)}>
            No
          </Button>
        </Flex>
      </div>
    );
  }

  if (response) {
    if (response.in_use) {
      return (
        <div>
          <Text align="center" c="white" fw={600} mb="sm">
            This membership ({value}) is being used by another rider.
          </Text>
          {additionalReqData && additionalReqData.dashboard === false && (
            <Text fw={600} align="center" c="white">
              Please continue the registration process and we'll get this
              sorted. More instructions to come later.
            </Text>
          )}
        </div>
      );
    }
    if (!response.is_active) {
      return (
        <div>
          <Text align="center" c="red" fw={600}>
            This membership ({value}) is inactive.
          </Text>
          <Flex justify="center">
            {!response.loyalty_membership_id && (
              <Button
                onClick={() => {
                  setResponse(null);
                  setValue("");
                }}
                size="xs"
                mt="sm"
                // variant="light"
              >
                Try again
              </Button>
            )}
          </Flex>
        </div>
      );
    }
    if (!response.verified) {
      return (
        <div>
          <Text c="red" fw={600}>
            We couldn't verify this membership ({value}).
          </Text>
          {!response.loyalty_membership_id && (
            <Button
              onClick={() => {
                setResponse(null);
                setValue("");
              }}
              size="xs"
              mt="sm"
              variant="light"
            >
              Try again
            </Button>
          )}
        </div>
      );
    }
  }

  return (
    <div>
      <TextInput
        label="Membership Number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        loading={loading}
        mt="xs"
        fullWidth
        disabled={!value}
        onClick={onCodeSubmit}
      >
        Submit
      </Button>
    </div>
  );
}

const OpenTroubleTicket = ({ reqData, onSuccess }) => {
  const [ticketOpen, setTicketOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setLoading(true);
    const req = {
      ...reqData,
    };

    axios
      .post(`/trouble-tickets/`, req)
      .then(() => {
        toast.success("Ticket Opened!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <>
      <Button loading={loading} onClick={() => onSubmit()} color="green">
        Open Ticket
      </Button>
    </>
  );
};
