import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

import "./Odometer.css";

const Odo = ({ newValue }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(newValue), 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [newValue]);

  return (
    <Odometer
      value={value}
      format="d"
      style={{
        fontSize: "40px",
      }}
    />
  );
};

export default Odo;
