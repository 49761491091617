import React, { act, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Flex, Button, Box, Text, UnstyledButton } from "@mantine/core";
import toast from "react-hot-toast";

import DealerVerification from "./DealerVerification";
import { UnverifiedItem, OdometerForm } from "./VehicleVerificationView";
import OdometerValueMismatch from "./OdometerValueMismatch";
import DealerWarnings from "./DealerWarnings";

export default function UnverifiedItems({
  items,
  additionalReqData,
  fetchData,
  verificationCode,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [value, setValue] = useState("");
  const [dealerSubmitting, setDealerSubmitting] = useState(false);

  useEffect(() => {
    setDealerCodeData(null);
    setValue("");
    setDealerSubmitting(false);

    if (verificationCode) {
      setDealerCodeData(verificationCode);
    }
  }, [activeItem]);

  function onDealerSubmit() {
    setDealerSubmitting(true);

    const req = {
      ...additionalReqData,
      verifier_remote_code_id: dealerCodeData.id,
      odometer_value: value,
      ride_challenge_data_point_id: activeItem.id,
      user_vehicle_id: activeItem.user_vehicle_id,
    };

    if (!activeItem.user_vehicle_id) {
      req.vin = activeItem.vin;
      req.checksum_results = activeItem.checksum_results;
      req.nhtsa_results = activeItem.nhtsa_results;
      req.year = activeItem.year;
      req.make = activeItem.make;
      req.model = activeItem.model;
      req.series = activeItem.series;
    }

    axios
      .post(`/ride-challenge/verify-reading/`, req)
      .then(() => {
        toast.success("Verified!");
        setDealerSubmitting(false);
        // fetchUser();
        setActiveItem(null);
        fetchData();
      })
      .then(() => fetchData())
      .catch((err) => {
        toast.error(err);
        setDealerSubmitting(false);
      });
  }

  function onDeleteClick(itemId) {
    axios
      .post(`/ride-challenge/inactivate-data-point/`, {
        ride_challenge_data_point_id: itemId,
      })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (items.length === 0) {
    return (
      <Text c="white" align="center">
        Nothing to do here at the moment.
      </Text>
    );
  }

  if (activeItem) {
    return (
      <div>
        {/* <Box>
          <Text c="white" fw={600} size="xl" align="center" mb="xl">
            {activeItem.vin}
          </Text>
        </Box> */}
        {dealerCodeData ? (
          <div>
            {!activeItem.user_vehicle_id && (
              <UnverifiedItem
                info={activeItem}
                additionalReqData={{
                  ...additionalReqData,
                  verifier_remote_code_id: dealerCodeData.id,
                }}
                fetchUser={() => {
                  setActiveItem(null);
                  fetchData();
                }}
              />
            )}
            {activeItem.user_vehicle_id && (
              <div>
                <DealerWarnings
                  reqData={{
                    user_id: activeItem.user_id,
                    campaign_effort_id: activeItem.campaign_effort_id,
                  }}
                />
                <Flex justify="center" mt="lg">
                  <OdometerForm value={value} onChange={(e) => setValue(e)} />
                </Flex>
                {activeItem.odometer_value && (
                  <OdometerValueMismatch
                    originalValue={activeItem.odometer_value}
                    newValue={value}
                  />
                )}
                <Flex justify="center" mt="lg">
                  <Button
                    onClick={onDealerSubmit}
                    disabled={value.length !== 6}
                    loading={dealerSubmitting}
                  >
                    Submit Reading
                  </Button>
                </Flex>
              </div>
            )}
          </div>
        ) : (
          <DealerVerification setDealerCodeData={(e) => setDealerCodeData(e)} />
        )}
        <Flex justify="center" mt="lg" gap="xs">
          <Button size="xs" variant="light" onClick={() => setActiveItem(null)}>
            back
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      {items.map((item, i) => {
        const source = item.user_vehicle || item;

        return (
          <div key={item.id}>
            <Box mb="lg">
              <Badge mb="xs" size="xs" variant="light">
                {item.is_new_vin
                  ? "Needs VIN Verification"
                  : "Needs Reading Verification"}
              </Badge>
              <Text c="white" fw={600}>
                {source.year} {source.make} {source.model}{" "}
                {source.series && `${source.series}`}
              </Text>
              <Text c="dimmed">
                {item.odometer_value} miles, {source.vin}
              </Text>
              <Flex gap="xs" align="center" mt="sm">
                <Button
                  size="xs"
                  onClick={() => setActiveItem(item)}
                  color="green"
                  radius="lg"
                >
                  Verify Now
                </Button>
                <Button
                  size="xs"
                  onClick={() => onDeleteClick(item.id)}
                  color="red"
                  radius="lg"
                >
                  Delete
                </Button>
              </Flex>
            </Box>
          </div>
        );
      })}
    </div>
  );
}
