import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import {
  Box,
  Button,
  Badge,
  Drawer,
  Grid,
  Flex,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useAppStore from "./ride-challenge-store";
import styled from "styled-components";
import toast from "react-hot-toast";

import DealerVerification from "./DealerVerification";

export default function UserMilestones({ reqData, setAppLoading }) {
  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    fetchMilestones();
  }, []);

  function fetchMilestones() {
    setAppLoading(true);

    axios
      .post(`/ride-challenge/milestones/`, reqData)
      .then(({ data }) => {
        setMilestones(data.response);
        setAppLoading(false);
      })
      .catch((err) => {
        setMilestones([]);
        setAppLoading(false);
      });
  }

  return (
    <Grid gutter="xl">
      {milestones.map((milestone) => (
        <Grid.Col span={{ base: 4, md: 4 }} key={milestone.id}>
          <MilestoneItem info={milestone} fetchData={fetchMilestones} />
        </Grid.Col>
      ))}
    </Grid>
  );
}

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `80px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `80px`)};
  object-fit: cover;
  border-radius: 5px;
  opacity: ${(props) => (props.earned ? 1 : 0.08)};
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `80px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `80px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MilestoneItem = ({ info, fetchData }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UnstyledButton onClick={open}>
          <Flex justify="center" mb="8px">
            {info.filename_url ? (
              <StyledImage
                earned={info.milestone_award_id ? true : false}
                src={info.filename_url}
              />
            ) : (
              <StyledPlaceholder />
            )}
          </Flex>
          <Text align="center" c="dimmed" size="xs">
            {info.title}{" "}
          </Text>
          {info.offer_pickup && (
            <Flex justify="center" mt="xs">
              <Badge size="xs">Clain Now</Badge>
            </Flex>
          )}
        </UnstyledButton>
      </div>
      <Drawer
        className="user-milestone-drawer"
        position="bottom"
        opened={opened}
        onClose={close}
      >
        {info.filename_url && <StyledImage earned src={info.filename_url} />}
        <Box mb="lg">
          <Text c="white" fw={600} size="xl">
            {info.title}
          </Text>
          <Text c="white">{info.description}</Text>
        </Box>
        {info.awarded_at_location_name && (
          <Text c="dimmed" size="sm">
            Earned at {info.awarded_at_location_name}
            {info.received_from && <>and received from {info.received_from}</>}
          </Text>
        )}
        {info.earned_at && (
          <Text c="dimmed" size="sm">
            {new Date(info.earned_at).toLocaleString()}
          </Text>
        )}
        {info.offer_pickup && (
          <Box mt="lg">
            <MilestoneClaim
              onSuccess={() => {
                fetchData();
                close();
              }}
              reqData={{
                milestone_award_id: info.milestone_award_id,
              }}
            />
          </Box>
        )}
      </Drawer>
    </div>
  );
};

const MilestoneClaim = ({ reqData, onSuccess }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [awarding, setAwarding] = useState(false);

  const { effortId, location, user } = useAppStore(
    (state) => ({
      effortId: state.effortId,
      location: state.location,
      user: state.user,
    }),
    shallow
  );

  function onAwardClick() {
    const req = {
      ...reqData,
      campaign_effort_id: effortId,
      location_id: location?.id,
      user_id: user.id,
      remote_code_id: dealerCodeData.id,
    };

    setAwarding(true);

    axios
      .post(`/ride-challenge/milestone-pickup/`, req)
      .then(() => {
        setAwarding(false);
        setDealerCodeData(null);
        onSuccess();
      })
      .catch((err) => {
        setAwarding(false);
        toast.error(err);
      });
  }

  if (dealerCodeData) {
    return (
      <div>
        <Button loading={awarding} onClick={() => onAwardClick()}>
          I've awarded the milestone
        </Button>
        <Flex mt="lg" justify="center">
          <Button
            color="gray"
            variant="subtle"
            onClick={() => {
              setShowScanner(false);
              setDealerCodeData(null);
            }}
          >
            Exit
          </Button>
        </Flex>
      </div>
    );
  }

  if (showScanner) {
    return (
      <div>
        <DealerVerification setDealerCodeData={(e) => setDealerCodeData(e)} />
        <Flex mt="lg" justify="center">
          <Button
            color="gray"
            variant="subtle"
            onClick={() => setShowScanner(false)}
          >
            Back
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <Button onClick={() => setShowScanner(true)}>
        Ready to claim this milestone?
      </Button>
    </div>
  );
};
