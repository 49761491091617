import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Input, Text, TextInput, Button } from "@mantine/core";
import { IconAt, IconDeviceMobile } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import styled from "styled-components";

import { CodeInput } from "@components/Auth";
import ViewTitle from "./ViewTitle";
import { iconSize } from "./helpers";
import useAppStore from "./ride-challenge-store";
import EffortRegistered from "./EffortRegistered";
import { UserLockModal } from "@components/User";

import { OtherButton } from "./UserLocation";

export default function UserCheck() {
  const [method, setMethod] = useState("phone");
  const [formValues, setFormValues] = useState({
    email: "",
    mobile_phone: "",
    code: "",
  });
  const [remoteCodeId, setRemoteCodeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lockData, setLockData] = useState(null);

  const { t } = useTranslation();

  const {
    campaignId,
    coords,
    effortId,
    entityInfo,
    location,
    colors,
    setActiveStep,
    setEmail,
    setEmailStreams,
    setPhone,
    setVerificationMethod,
    setRegistrationInfo,
    setUser,
  } = useAppStore(
    (state) => ({
      campaignId: state.campaignId,
      colors: state.colors,
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      location: state.location,
      setActiveStep: state.setActiveStep,
      setVerificationMethod: state.setVerificationMethod,
      setEmail: state.setEmail,
      setEmailStreams: state.setEmailStreams,
      setRegistrationInfo: state.setRegistrationInfo,
      setUser: state.setUser,
      setPhone: state.setPhone,
    }),
    shallow
  );

  const reqInfo = {};

  const locationId = entityInfo.location_id
    ? entityInfo.location_id
    : location
    ? location.id
    : null;

  if (locationId) reqInfo.location_id = locationId;
  if (effortId) reqInfo.campaign_effort_id = effortId;
  if (campaignId) reqInfo.campaign_id = campaignId;
  if (coords) {
    reqInfo.latitude = coords.latitude;
    reqInfo.longitude = coords.longitude;
  }

  useEffect(() => {
    if (formValues.code.length === 6) {
      verifyCode();
    }
  }, [formValues.code]);

  function onSubmit() {
    setLoading(true);

    if (method === "email")
      localStorage.setItem("mixerEntryEmail", formValues.email);

    const req = {
      ...reqInfo,
    };

    if (method === "email") req.email = formValues.email;
    if (method === "phone") req.mobile_phone = formValues.mobile_phone;

    axios
      .post(`/microsite/send-verification/`, req)
      .then(({ data }) => {
        setLoading(false);
        setRemoteCodeId(data.response[0].remote_code_id);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function verifyCode() {
    const req = {
      code: formValues.code,
      remote_code_id: remoteCodeId,
    };

    axios
      .post(`/microsite/verify-code/`, req)
      .then(({ data }) => {
        setVerificationMethod(method === "email" ? "email" : "mobile_phone");
        userLookup();
      })
      .catch((err) => {
        setFormValues({
          ...formValues,
          code: "",
        });
        toast.error(err);
      });
  }

  function userLookup() {
    const req = {
      ...reqInfo,
    };

    if (method === "email") req.email = formValues.email;
    if (method === "phone") req.mobile_phone = formValues.mobile_phone;

    axios
      .post(`/ride-challenge/user-lookup/`, req)
      .then(({ data }) => {
        const usr = data.response[0];

        if (usr) {
          if (usr.modal) {
            return setLockData(usr);
          }

          setUser(usr);
          setEmail(usr.email || formValues.email);
          if (usr.email_streams && usr.email_streams.length) {
            setEmailStreams(
              usr.email_streams.map((m) => ({
                ...m,
                value: m.active,
                label: m.name,
              }))
            );
          }
          if (method === "phone") {
            setPhone(formValues.mobile_phone);
          } else {
            if (usr.mobile_phone) {
              setPhone(usr.mobile_phone);
            }
          }
        } else {
          if (method === "email") {
            setEmail(formValues.email);
          } else {
            setPhone(formValues.mobile_phone);
          }
        }

        if (effortId) {
          const foundEffort = usr?.campaign_efforts.find(
            (f) => f.id === effortId
          );
          if (foundEffort) {
            setRegistrationInfo(foundEffort);

            if (!foundEffort.registered) return;

            if (foundEffort.registered && !foundEffort.entered) {
              if (foundEffort.has_unverified_readings) {
                return setActiveStep("dashboard");
              } else {
                return setActiveStep("vehicle");
              }
            }

            setActiveStep("dashboard");

            // if (
            //   foundEffort.initial_user_interaction_id &&
            //   foundEffort.needs_initial_reading
            // ) {
            //   return setActiveStep("vehicle");
            // }

            // if (!foundEffort.entered && foundEffort.has_unverified_readings) {
            //   return setActiveStep("vehicle_verification");
            // }

            // if (foundEffort.user_vehicles.length) {
            //   setActiveStep("dashboard");
            // }
          }
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const validEmail = formValues.email.match(/^\S+@\S+\.\S+$/) ? true : false;

  const MethodIcon = () =>
    method === "email" ? (
      <IconAt size={iconSize} color={colors.icon} />
    ) : (
      <IconDeviceMobile size={iconSize} color={colors.icon} />
    );

  if (remoteCodeId) {
    return (
      <div>
        {/* <UserLockModal data={lockData} /> */}
        <ViewTitle
          title={t("phone_code_title")}
          icon={<MethodIcon />}
          subtitle={`${t("phone_code_subtitle")} ${
            method === "email" ? formValues.email : formValues.mobile_phone
          }`}
        />
        <CodeInput
          value={formValues.code}
          inputMode="numeric"
          oneTimeCode
          onChange={(e) =>
            setFormValues({
              ...formValues,
              code: e,
            })
          }
        />
        <OtherButton
          onClick={() => {
            setRemoteCodeId(null);
            setFormValues({
              ...formValues,
              code: "",
            });
          }}
          text={t("back")}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ViewTitle
        title={t(method === "email" ? "email_question" : "phone_question")}
        icon={<MethodIcon />}
      />
      {method === "phone" && (
        <React.Fragment>
          <StyledPhone>
            <Input.Wrapper>
              <Input
                component={InputMask}
                placeholder="(XXX) XXX-XXXX"
                mask="(999) 999-9999"
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    mobile_phone: e.target.value,
                  });
                }}
                value={formValues.mobile_phone}
              />
            </Input.Wrapper>
          </StyledPhone>
        </React.Fragment>
      )}
      {method === "email" && (
        <TextInput
          value={formValues.email}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              email: e.target.value,
            })
          }
          placeholder="email"
          size="lg"
          type="email"
        />
      )}
      <Button
        fullWidth
        mt="sm"
        size="lg"
        onClick={onSubmit}
        disabled={
          method === "email"
            ? !formValues.email || !validEmail
            : !formValues.mobile_phone
        }
        loading={loading}
      >
        {t(method === "email" ? "email_button" : "phone_button")}
      </Button>
      {method === "phone" && (
        <Text align="center" mt="lg" c="white" size="sm">
          {t("phone_disclosure")}
        </Text>
      )}
      <div
        style={{
          marginTop: "2em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => setMethod(method === "email" ? "phone" : "email")}
          variant="light"
          size="xs"
        >
          {t("auth_method_toggle_button", {
            method: method === "email" ? t("phone") : "email",
          })}
        </Button>
      </div>
    </React.Fragment>
  );
}

const StyledPhone = styled.div`
  input {
    height: 50px;
    line-height: 48px;
    padding: 20px;
    font-size: 18px;
  }
`;
