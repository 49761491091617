import React, { useContext, useState, useEffect } from "react";
import { Group, Divider, Title, TextInput } from "@mantine/core";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import { CustomTooltip } from "@components/shared";
import { Context } from "./";

const isNum = (num) => new RegExp("^[0-9]+$").test(num);

export default function RideChallengeSettings({ onUpdate }) {
  const { rev_score: revScoreData, ride_challenge_warnings: warnings } =
    useContext(Context);
  const [tempValues, setTempValues] = useState({
    max_daily_mileage: "",
    vin_user_limit: "",
  });

  useEffect(() => {
    if (!revScoreData) return;

    if (warnings && (warnings.max_daily_mileage || warnings.vin_user_limit)) {
      setTempValues({
        ...tempValues,
        max_daily_mileage: warnings.max_daily_mileage || "",
        vin_user_limit: warnings.vin_user_limit || "",
      });
    }
  }, [JSON.stringify(warnings)]);

  if (!revScoreData) return null;

  return (
    <div>
      <Title order={4}>Warnings</Title>
      <TextInput
        label="Max Daily Mileage"
        value={tempValues.max_daily_mileage}
        onChange={(e) =>
          setTempValues({
            ...tempValues,
            max_daily_mileage: e.target.value,
          })
        }
        onBlur={() => {
          if (!isNum(tempValues.max_daily_mileage)) {
            return setTempValues({
              ...tempValues,
              max_daily_mileage: "",
            });
          }
          onUpdate({
            ride_challenge_warnings: {
              ...warnings,
              max_daily_mileage: parseInt(tempValues.max_daily_mileage),
            },
          });
        }}
      />
      <TextInput
        label="VIN User Limit"
        value={tempValues.vin_user_limit}
        onChange={(e) =>
          setTempValues({
            ...tempValues,
            vin_user_limit: e.target.value,
          })
        }
        onBlur={() => {
          if (!isNum(tempValues.vin_user_limit)) {
            return setTempValues({
              ...tempValues,
              vin_user_limit: "",
            });
          }
          onUpdate({
            ride_challenge_warnings: {
              ...warnings,
              vin_user_limit: parseInt(tempValues.vin_user_limit),
            },
          });
        }}
      />
      <Divider mt="lg" mb="lg" />
      <Group>
        <Counter
          unlimited={false}
          minValue={1}
          value={revScoreData.keywords_engagement_multiplier}
          onChange={(e) => {
            onUpdate({
              rev_score: {
                ...revScoreData,
                keywords_engagement_multiplier: e,
              },
            });
          }}
        />
        <Title order={3}>Keywords Engagement Multiplier</Title>
      </Group>
      <Group>
        <Counter
          unlimited={false}
          minValue={1}
          value={revScoreData.readings_engagement_multiplier}
          onChange={(e) => {
            onUpdate({
              rev_score: {
                ...revScoreData,
                readings_engagement_multiplier: e,
              },
            });
          }}
        />
        <Title order={3}>Readings Engagement Multiplier</Title>
      </Group>
    </div>
  );
}
