import React, { useState } from "react";
import { Flex, TextInput, Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { Scanner } from "@yudiel/react-qr-scanner";
import styled from "styled-components";

export default function DealerVerification({ setDealerCodeData, size = 250 }) {
  const [codeValue, setCodeValue] = useState("");

  function onCodeSubmit(scannedCodeValue) {
    const req = {
      code: scannedCodeValue || codeValue,
    };

    axios
      .post(`/ride-challenge/verification-code-check/`, req)
      .then(({ data }) => {
        setDealerCodeData(data.response[0]);
      })
      .catch((err) => {
        setCodeValue("");
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex justify="center" mb="xl">
        <StyledScanner size={size}>
          <Scanner
            styles={{
              container: {},
            }}
            onScan={(result) => {
              if (result.length && result[0].rawValue) {
                // setCodeValue(result[0].rawValue);
                onCodeSubmit(result[0].rawValue);
              }
            }}
          />
        </StyledScanner>
      </Flex>
      {import.meta.env.VITE_ENV === "dev" && (
        <>
          <TextInput
            label="Dealer Code"
            value={codeValue}
            onChange={(e) => setCodeValue(e.target.value)}
          />
          <Button
            mt="xs"
            fullWidth
            disabled={!codeValue}
            onClick={() => onCodeSubmit()}
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
}

const StyledScanner = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.size ? `${props.size}px` : "250px")};
  width: ${(props) => (props.size ? `${props.size}px` : "250px")};

  svg {
    border: none !important;
  }
`;
