import React from "react";
import styled from "styled-components";

import { PinnedReportRecipeList } from "@components/ReportRecipe";

export default function Dashboard() {
  return (
    <div>
      <PinnedReportRecipeList
        reqData={{
          pinned_to: 1,
        }}
      />
    </div>
  );
}

const StyledOdometer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .odometer-value {
    /* font-size: 40px; */
  }
`;
