import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  Flex,
  Badge,
  Text,
  Box,
  Title,
  Loader,
  ColorInput,
  TextInput,
  Select,
  Grid,
  Card,
  Divider,
  ActionIcon,
  List,
  Input,
  rem,
  FileButton,
} from "@mantine/core";
import toast from "react-hot-toast";
import {
  IconPencil,
  IconCaretDownFilled,
  IconCamera,
} from "@tabler/icons-react";
import styled from "styled-components";
import { RideChallengeRevScoreGauge } from "@components/RideChallenge";

import { ContestTierReorder } from "./";

export default function ContestTierGroupManagement({
  contestId,
  organizationId,
  locationId,
}) {
  const [tierGroups, setTierGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      contest_id: contestId,
    };

    axios
      .post(`/contests/${contestId}/retrieve-contest-tier-groups/`, req)
      .then(({ data }) => {
        setTierGroups(data.response.sort((a, b) => a.position - b.position));
        setLoading(false);
      })
      .catch((err) => {
        setTierGroups([]);
        setLoading(false);
      });
  }

  const tierGroupVarieties = tierGroups.map((m) => `${m.variety}`);

  return (
    <div>
      {tierGroups.map((tierGroup) => (
        <TierGroup
          key={tierGroup.id}
          tierGroupId={tierGroup.id}
          info={tierGroup}
          contestId={contestId}
          disabledTierGroupVarietyValues={tierGroupVarieties}
          fetchTierGroups={fetchData}
          organizationId={organizationId}
          locationId={locationId}
        />
      ))}
      <Grid>
        <Grid.Col
          span={{
            base: 12,
            md: 6,
          }}
        >
          <Flex gap="xs" justify="center">
            <TierGroupAdd
              contestId={contestId}
              onSuccess={fetchData}
              disabledValues={tierGroupVarieties}
            />
            <ContestTierReorder
              entity="ContestTierGroup"
              items={tierGroups.map((m) => ({
                id: m.id,
                name: `${m.name} (${m.variety_formatted})`,
                position: m.position,
              }))}
              onSuccess={() => fetchData()}
            />
          </Flex>
        </Grid.Col>
      </Grid>
    </div>
  );
}

const TierGroup = ({
  info,
  contestId,
  tierGroupId,
  disabledTierGroupVarietyValues,
  fetchTierGroups,
  organizationId,
  locationId,
}) => {
  const [isOpen, setOpen] = useState(false);

  // useEffect(() => {
  //   if (!isOpen) return;
  //   fetchTierGroup();
  // }, [isOpen]);

  // function fetchTierGroup() {
  //   axios
  //     .get(`/contests/${contestId}/contest-tier-groups/${tierGroupId}/`)
  //     .then(({ data }) => {
  //       setInfo({
  //         ...data.response[0],
  //         contest_tiers: data.response[0].contest_tiers.sort(
  //           (a, b) => a.position - b.position
  //         ),
  //       });
  //     })
  //     .catch((err) => {
  //       setInfo(null);
  //     });
  // }

  const imageUrl =
    info.assets && info.assets.length ? info.assets[0].filename_url : null;

  return (
    <Grid align="center" mb={isOpen ? "xl" : "xs"}>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Card>
          <Flex gap="xs" align="center">
            <div>
              <TierImage
                onSuccess={fetchTierGroups}
                contestId={contestId}
                tierGroupId={info.id}
                url={imageUrl}
                height={75}
                width={75}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <Flex gap="xs" align="center">
                <Text fw={600}>{info.name || info.variety_formatted}</Text>
                <Badge variant="light">{info.variety_formatted}</Badge>
                <TierGroupEdit
                  tierGroupId={info.id}
                  onSuccess={fetchTierGroups}
                  contestId={contestId}
                  info={info || {}}
                  disabledTierGroupVarietyValues={
                    disabledTierGroupVarietyValues
                  }
                  fetchTiers={fetchTierGroups}
                />
                <ExpandButton
                  expanded={isOpen}
                  onClick={() => setOpen(!isOpen)}
                />
              </Flex>
              <Text
                c={info.contest_tiers.length === 0 ? "red" : "dimmed"}
                size="sm"
              >
                {info.contest_tiers.length} Tier
                {info.contest_tiers.length === 1 ? "" : "s"}
              </Text>
            </div>
            {info.contest_tiers.length > 0 && (
              <div style={{ height: "80px", width: "80px" }}>
                <RideChallengeRevScoreGauge
                  tiers={info.contest_tiers}
                  showPointer={false}
                />
              </div>
            )}
          </Flex>
        </Card>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        {isOpen && (
          <div
            style={{
              background: "var(--mantine-color-gray-1)",
              padding: "var(--mantine-spacing-sm)",
              borderRadius: "5px",
              border: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            {info.contest_tiers
              .sort((a, b) => a.position - b.position)
              .map((tier) => (
                <Tier
                  key={tier.id}
                  info={tier}
                  contestId={contestId}
                  contestTierId={tier.id}
                  fetchTierGroups={fetchTierGroups}
                  organizationId={organizationId}
                  locationId={locationId}
                />
              ))}
            <Flex justify="center" gap="xs">
              <TierAdd
                contestId={contestId}
                additionalReqData={{
                  contest_tier_group_id: tierGroupId,
                  contest_id: contestId,
                }}
                onSuccess={fetchTierGroups}
              />
              {info.contest_tiers.length > 1 && (
                <ContestTierReorder
                  entity="ContestTier"
                  items={info.contest_tiers.map((m) => ({
                    id: m.id,
                    name: `${m.name}`,
                    position: m.position,
                  }))}
                  onSuccess={() => fetchTierGroups()}
                />
              )}
            </Flex>
          </div>
        )}
      </Grid.Col>
    </Grid>
  );
};

const Tier = ({
  info,
  contestId,
  fetchTierGroups,
  organizationId,
  locationId,
}) => {
  // const [info, setInfo] = useState(null);
  const [isOpen, setOpen] = useState(false);

  // useEffect(() => {
  //   if (!isOpen) return;
  //   fetchTier();
  // }, [isOpen]);

  // function fetchTier() {
  //   axios
  //     .get(`/contests/${contestId}/contest-tiers/${contestTierId}/`)
  //     .then(({ data }) => {
  //       setInfo(data.response[0]);
  //     })
  //     .catch((err) => {
  //       setInfo(null);
  //     });
  // }

  const imageUrl =
    info.assets && info.assets.length
      ? info.assets[info.assets.length - 1].filename_url
      : null;

  return (
    <Card key={info.id} mb="sm">
      <Flex gap="sm" align="flex-start">
        <div>
          <TierImage
            onSuccess={() => fetchTierGroups()}
            contestId={contestId}
            tierId={info.id}
            url={imageUrl}
            height={75}
            width={75}
          />
        </div>
        <div>
          <Flex gap="xs" align="center">
            <Text fw={600}>{info.name || info.id}</Text>
            {info?.settings?.fill_color && (
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: info.settings.fill_color,
                  borderRadius: "5px",
                }}
              ></div>
            )}
            <Badge>
              {info.start_value} - {info.end_value}
            </Badge>
            {/* {!isOpen && (
              <div>
                <ExpandButton onClick={() => setOpen(!isOpen)} />
              </div>
            )} */}
            <TierEdit
              tierId={info.id}
              onSuccess={fetchTierGroups}
              info={info || {}}
              contestId={contestId}
              fetchTierGroup={fetchTierGroups}
            />
          </Flex>
          <>
            <Box mt="xs">
              {info.prizes.map((prize) => (
                <Box key={prize.id} mb="sm">
                  <Prize
                    info={prize}
                    contestId={contestId}
                    tierId={info.id}
                    prizeId={prize.id}
                    fetchData={fetchTierGroups}
                    organizationId={organizationId}
                    locationId={locationId}
                  />
                </Box>
              ))}
              {info.prizes.length === 0 && (
                <Text size="sm" fw={600} c="red">
                  No prizes yet!
                </Text>
              )}
            </Box>
          </>
        </div>
      </Flex>
    </Card>
  );
};

const TierGroupAdd = ({ contestId, disabledValues, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button size="xs" onClick={() => setOpen(true)}>
        Add Tier Group
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <TierGroupForm
          contestId={contestId}
          disabledValues={disabledValues}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
      </Modal>
    </div>
  );
};

const TierGroupForm = ({
  contestId,
  id,
  disabledValues,
  onSuccess,
  name = "",
  variety = "",
}) => {
  const [formValues, setFormValues] = useState({
    name,
    variety,
  });
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    if (id) return onUpdate();
    const req = {
      ...formValues,
      contest_id: contestId,
    };

    setLoading(true);

    axios
      .post(`/contests/${contestId}/contest-tier-groups/`, req)
      .then(() => {
        toast.success("Created!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onUpdate() {
    const req = {
      ...formValues,
      contest_id: contestId,
      contest_tier_group_id: id,
    };

    setLoading(true);

    axios
      .put(`/contests/${contestId}/contest-tier-groups/${id}/`, req)
      .then(() => {
        toast.success("Updated");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <>
      <TextInput
        label="Tier Group Name"
        value={formValues.name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            name: e.target.value,
          })
        }
      />
      <Select
        label="Variety"
        value={formValues.variety}
        placeholder="Select one..."
        data={[
          { label: "Mileage", value: "1" },
          { label: "Location Visits", value: "2" },
          { label: "Keyword Claims", value: "3" },
          { label: "Check-Ins", value: "4" },
        ].map((m) => ({
          ...m,
          disabled: disabledValues.includes(m.value),
        }))}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            variety: e,
          })
        }
      />
      <Button
        mt="sm"
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={!formValues.name || !formValues.variety}
      >
        Submit
      </Button>
    </>
  );
};

const TierAdd = ({ contestId, additionalReqData, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button size="xs" onClick={() => setOpen(true)}>
        Add Tier
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <TierForm
          contestId={contestId}
          additionalReqData={additionalReqData}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
      </Modal>
    </div>
  );
};

const TierForm = ({
  additionalReqData,
  contestId,
  onSuccess,
  name = "",
  id,
  startValue = "",
  endValue = "",
  settings = {
    fill_color: "",
  },
}) => {
  const [formValues, setFormValues] = useState({
    name,
    start_value: startValue,
    end_value: endValue,
    settings,
  });
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    if (id) return onUpdate();
    const req = {
      ...additionalReqData,
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/contests/${contestId}/contest-tiers/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onUpdate() {
    const req = {
      ...formValues,
    };

    setLoading(true);

    axios
      .put(`/contests/${contestId}/contest-tiers/${id}/`, req)
      .then(() => {
        toast.success("Updated");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <TextInput
        label="Tier Name"
        value={formValues.name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            name: e.target.value,
          })
        }
      />
      <TextInput
        label="Start Value"
        value={formValues.start_value}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            start_value: e.target.value,
          })
        }
      />
      <TextInput
        label="End Value"
        value={formValues.end_value}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            end_value: e.target.value,
          })
        }
      />
      <ColorInput
        label="Progress Fill Color"
        value={formValues.settings.fill_color}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            settings: {
              ...formValues.settings,
              fill_color: e,
            },
          })
        }
      />
      <Button
        mt="sm"
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={
          !formValues.name ||
          formValues.start_value === "" ||
          !formValues.settings.fill_color ||
          !formValues.end_value
        }
      >
        Submit
      </Button>
    </div>
  );
};

const TierGroupEdit = ({
  contestId,
  info,
  onSuccess,
  tierGroupId,
  disabledTierGroupVarietyValues,
  fetchTiers,
}) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <EditButton onClick={() => setOpen(true)} />
      <Modal title="Edit Tier Group" opened={isOpen} onClose={onClose}>
        <TierGroupForm
          id={tierGroupId}
          name={info.name}
          variety={`${info.variety}`}
          contestId={contestId}
          disabledValues={disabledTierGroupVarietyValues}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
        <Divider mt="lg" mb="lg" />
        <RemoveTierGroup
          tierGroupId={tierGroupId}
          onSuccess={() => {
            onClose();
            fetchTiers();
          }}
        />
      </Modal>
    </div>
  );
};

const TierEdit = ({ contestId, info, onSuccess, tierId, fetchTierGroup }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <EditButton onClick={() => setOpen(true)} />
      <Modal title="Edit Tier Group" opened={isOpen} onClose={onClose}>
        <TierForm
          id={tierId}
          name={info.name}
          startValue={info.start_value}
          settings={info.settings}
          endValue={info.end_value}
          contestId={contestId}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
        <Divider mt="lg" mb="lg" />
        <RemoveTier
          tierId={tierId}
          onSuccess={() => {
            onClose();
            fetchTierGroup();
          }}
        />
      </Modal>
    </div>
  );
};

const RemoveTier = ({ tierId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    axios
      .post(`/delete-contest-tier/`, {
        contest_tier_id: tierId,
      })
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Flex justify="center">
      <Button
        onClick={onSubmit}
        loading={loading}
        color="gray"
        variant="subtle"
        size="xs"
      >
        Remove
      </Button>
    </Flex>
  );
};

const RemoveTierGroup = ({ tierGroupId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    axios
      .post(`/delete-contest-tier-group/`, {
        contest_tier_group_id: tierGroupId,
      })
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Flex justify="center">
      <Button
        onClick={onSubmit}
        loading={loading}
        color="gray"
        variant="subtle"
        size="xs"
      >
        Remove
      </Button>
    </Flex>
  );
};

const Prize = ({ info }) => {
  return (
    <div>
      <Text fw={600}>{info.name}</Text>
      {info.loot_items.length > 0 && (
        <List size="sm">
          {info.loot_items.map((lootItem) => (
            <List.Item key={lootItem.loot_item_id}>
              ({lootItem.quantity}) {lootItem.name}
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};

const EditButton = ({ onClick }) => {
  return (
    <ActionIcon
      radius="xl"
      size="md"
      variant="light"
      color="gray"
      onClick={onClick}
    >
      <IconPencil style={{ width: rem(16), height: rem(16) }} />
    </ActionIcon>
  );
};

const ExpandButton = ({ onClick, expanded = false }) => {
  return (
    <ActionIcon
      radius="xl"
      size="md"
      variant="light"
      color="gray"
      onClick={onClick}
    >
      <IconCaretDownFilled
        style={{
          width: rem(16),
          height: rem(16),
          transform: `rotate(${expanded ? "-90deg" : "0"})`,
        }}
        rotate={expanded ? "90deg" : 0}
      />
    </ActionIcon>
  );
};

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export function TierImage({
  tierId,
  tierGroupId,
  contestId,
  onSuccess,
  url,
  height,
  width,
}) {
  const ids = {
    contest_id: contestId,
    contest_tier_id: tierId,
    contest_tier_group_id: tierGroupId,
  };

  const reqData = Object.keys(ids).reduce((acc, cur) => {
    if (ids[cur]) {
      acc[cur] = ids[cur];
    }
    return acc;
  }, {});

  function onUpload(file) {
    const formData = new FormData();
    formData.append("tempfile", file);
    formData.append("variety", tierId ? 18 : 17);
    Object.keys(reqData).forEach((k) => formData.append(k, reqData[k]));

    axios
      .post(`/assets/`, formData)
      .then(() => {
        toast.success("Uploaded!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <FileButton accept="image/*" onChange={(e) => onUpload(e)}>
      {(props) =>
        url ? (
          <StyledImage
            {...props}
            src={url || null}
            height={height}
            width={width}
          />
        ) : (
          <StyledPlaceholder {...props} height={height} width={width}>
            <IconCamera size={24} color="var(--mantine-color-gray-6)" />
          </StyledPlaceholder>
        )
      }
    </FileButton>
  );
}
