import React, { useState } from "react";
import { Box, Divider, Switch, Button, Flex, Text } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { LogoUpload } from "@components/Asset";
import { MilestoneForm } from "./";

const MilestoneItem = ({ info, id, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);
  if (!info) return null;

  function onClose() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <Box mb="lg">
        <Flex justify="center">
          <LogoUpload
            url={info.filename_url}
            onSuccess={() => onSuccess()}
            milestoneId={id}
          />
        </Flex>
        <Text align="center" mt="xs" size="xs">
          Recommended badge size is 100x100 pixels
        </Text>
      </Box>
      <MilestoneForm
        title={info.title}
        description={info.description}
        value={info.value}
        multipleAward={info.multiple_award}
        requiresPickup={info.requires_pickup}
        startAt={info.start_at}
        endAt={info.end_at}
        onSuccess={onClose}
        id={id}
      />
      <Divider mt="lg" mb="lg" />
      <MilestoneStatus id={id} onSuccess={onClose} value={info.status} />
      {info.can_delete && (
        <>
          <Divider mt="lg" mb="lg" />
          <MilestoneDelete id={id} onSuccess={onClose} />
        </>
      )}
    </div>
  );
};

export default MilestoneItem;

const MilestoneStatus = ({ value, onSuccess, id }) => {
  function onChange() {
    axios
      .post(`/milestones/toggle-status/`, { milestone_id: id })
      .then(() => onSuccess())
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex gap="xs">
        <Button
          onClick={onChange}
          size="xs"
          color="green"
          variant={value === 1 ? "filled" : "light"}
        >
          Active
        </Button>
        <Button
          onClick={onChange}
          size="xs"
          color="red"
          variant={value === 2 ? "filled" : "light"}
        >
          Inactive
        </Button>
      </Flex>
    </div>
  );
};

const MilestoneDelete = ({ onSuccess, id }) => {
  function onClick() {
    axios
      .delete(`/milestones/${id}/`)
      .then(() => onSuccess())
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex gap="xs" justify="center">
        <Button onClick={onClick} size="xs" color="gray" variant="subtle">
          Delete Milestone
        </Button>
      </Flex>
    </div>
  );
};
