import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  TextInput,
  Text,
  Title,
  Flex,
  Loader,
  PinInput,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

export default function VinSetup({
  additionalReqData,
  onSuccess,
  isAdditionalVehicle = false,
  verificationCode,
}) {
  const [vinInfo, setVinInfo] = useState(null);
  const [odometerInfo, setOdometerInfo] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    if (odometerInfo) {
      createReading();
    }
  }, [JSON.stringify(odometerInfo)]);

  function createReading() {
    const req = {
      ...additionalReqData,
      make: vinInfo.make,
      vin: vinInfo.vin,
      year: vinInfo.year,
      model: vinInfo.model,
      odometer_value: odometerInfo.value,
    };

    setSubmitting(false);

    const route = isAdditionalVehicle
      ? `/ride-challenge/submit-new-vehicle/`
      : `/ride-challenge/submit-initial-reading/`;

    axios
      .post(route, req)
      .then(({ data }) => {
        setSubmitting(false);
        onSuccess(req);
      })
      .catch((err) => {
        setSubmitting(false);
        setSubmitError(err);
      });
  }

  if (odometerInfo) {
    if (submitError) {
      return (
        <div>
          <Text align="center" c="white">
            {submitError}
          </Text>
          <Flex justify="center" mt="xl">
            <Button
              onClick={() => {
                setSubmitError(null);
                setOdometerInfo(null);
                setVinInfo(null);
              }}
            >
              retry
            </Button>
          </Flex>
        </div>
      );
    }

    return (
      <div>
        {submitting ? (
          <Flex justify="center" align="center" gap="sm">
            <Loader />
            <Text c="white" size="xs">
              Generating next steps...
            </Text>
          </Flex>
        ) : (
          <Text c="white" align="center">
            <Loader />
          </Text>
        )}
      </div>
    );
  }

  if (vinInfo) {
    return (
      <div>
        <OdometerReading onSuccess={(e) => setOdometerInfo(e)} />
      </div>
    );
  }

  return (
    <div>
      <VinForm onSuccess={(newVinInfo) => setVinInfo(newVinInfo)} />
    </div>
  );
}

export function VinForm({ onSuccess, showModel }) {
  const [formValues, setFormValues] = useState({
    vin: "",
    year: "",
  });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const options = generateOptions(1950);

  function onConfirm() {
    setConfirm(true);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
      make: "HARLEY-DAVIDSON",
    };

    onSuccess(req);
  }

  const needModel =
    showModel || (formValues.year && parseInt(formValues.year) <= 1980);

  if (confirm) {
    return (
      <div>
        <Text mb="lg" c="white" align="center">
          Please confirm the following information is correct:
        </Text>
        <Title c="white" align="center">
          {formValues.year}
          {formValues.model ? ` ${formValues.model}` : ""}
        </Title>
        <Text c="white" align="center" size="xl">
          VIN: {formValues.vin}
        </Text>
        <Flex justify="center" mt="lg">
          <Button
            disabled={!formValues.vin || !formValues.year}
            onClick={onSubmit}
            loading={loading}
          >
            Looks good!
          </Button>
        </Flex>
        <Flex justify="center" mt="sm">
          <Button
            disabled={loading}
            size="xs"
            onClick={() => setConfirm(false)}
            variant="subtle"
            color="gray"
          >
            I need to change something
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Select
          label="Model Year"
          data={options}
          placeholder="Select one"
          value={formValues.year}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              year: e,
              model: "",
            })
          }
        />
        <TextInput
          label="VIN"
          value={formValues.vin}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              vin: e.target.value,
            })
          }
        />
        {needModel && (
          <ModelSelect
            label="Model"
            value={formValues.model}
            reqData={{
              year: formValues.year,
            }}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                model: e,
              })
            }
          />
        )}
        <Button
          mt="sm"
          disabled={
            !formValues.vin ||
            !formValues.year ||
            (showModel && !formValues.model)
          }
          onClick={onConfirm}
          loading={loading}
          fullWidth
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

const generateOptions = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years
    .sort((a, b) => b - a)
    .map((m) => ({
      label: `${m}`,
      value: `${m}`,
    }));
};

function OdometerReading({ onSuccess }) {
  const [formValues, setFormValues] = useState({
    value: "",
  });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function onConfirm() {
    setConfirm(true);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
    };

    onSuccess(req);
  }

  if (confirm) {
    return (
      <div>
        <Text align="center" c="white" mb="lg">
          Please confirm the following information is correct:
        </Text>
        <Title align="center" c="white" order={1}>
          {formValues.value.replace(/^0+/, "")} miles
        </Title>
        <Flex justify="center" mt="xl" gap="sm">
          <Button
            disabled={!formValues.value}
            onClick={onSubmit}
            loading={loading}
          >
            Looks good!
          </Button>
        </Flex>
        <Flex justify="center" mt="sm">
          <Button
            disabled={loading}
            size="xs"
            onClick={() => setConfirm(false)}
            variant="subtle"
            color="gray"
          >
            I need to change something
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Text align="center" c="white" mb="sm">
          Odometer Reading
        </Text>
        <Flex justify="center">
          <PinInput
            length={6}
            placeholder="-"
            label="Odometer"
            inputMode="numeric"
            autoFocus
            size="sm"
            value={formValues.value}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                value: e,
              })
            }
          />
        </Flex>
        <Flex justify="center" mt="lg">
          <Button
            disabled={!formValues.value}
            onClick={onConfirm}
            loading={loading}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </div>
  );
}

function ModelSelect({ value, onChange, reqData }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, [JSON.stringify(reqData)]);

  function fetchOptions() {
    const req = {
      ...reqData,
    };

    axios
      .post(`/ride-challenge/legacy-models/`, req)
      .then(({ data }) => {
        setOptions(
          data.response[0].models.map((d) => ({
            label: d,
            value: d,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  return (
    <Select
      label="Model"
      value={value}
      onChange={(e) => onChange(e)}
      data={options}
      placeholder="Select one..."
    />
  );
}
