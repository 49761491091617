import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex, List, Button, Modal } from "@mantine/core";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

export default function DealerWarnings({ reqData }) {
  const [warnings, setWarnings] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWarnings();
  }, []);

  function fetchWarnings() {
    const req = {
      ...reqData,
    };

    axios
      .post("/ride-challenge/retrieve-warnings/", req)
      .then(({ data }) => {
        setWarnings(data.response);
      })
      .catch((err) => {
        setWarnings([]);
      });
  }

  function onClose() {
    setOpen(false);
  }

  if (!warnings.length) return null;

  return (
    <div>
      <Flex justify="center">
        <Button
          leftSection={<IconAlertTriangleFilled size={16} />}
          size="xs"
          variant="light"
          color="yellow"
          onClick={() => setOpen(true)}
          radius="xl"
        >
          View Warnings
        </Button>
      </Flex>
      <Modal opened={isOpen} onClose={onClose}>
        <List>
          {warnings.map((warning, i) => (
            <List.Item key={i}>{warning}</List.Item>
          ))}
        </List>
      </Modal>
    </div>
  );
}
