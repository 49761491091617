import React from "react";
import { Text } from "@mantine/core";

export default function OdometerValueMismatch({ originalValue, newValue }) {
  if (
    newValue.length === 6 &&
    originalValue &&
    newValue.replace(/^0+/, "") !== `${originalValue}`
  ) {
    return (
      <Text mt="sm" size="xs" align="center" c="red" fw={600}>
        Your reading and the customer's reading do not match!
      </Text>
    );
    // return (
    //   <Text mt="sm" size="xs" align="center" c="red" fw={600}>
    //     Customer entered odometer reading: <span>{originalValue}</span>.
    //     <br />
    //     Your reading and their reading do not match!
    //   </Text>
    // );
  } else {
    return null;
  }
}
