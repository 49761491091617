import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Box,
  Flex,
  Divider,
  Text,
  Table,
  Anchor,
  TextInput,
  Modal,
  Button,
  ColorSwatch,
  LoadingOverlay,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  IconPencil,
  IconSettings,
  IconDeviceFloppy,
} from "@tabler/icons-react";
import styled from "styled-components";

import { IntervalItem } from "@components/shared/Intervals";
import entityIcon from "@util/entityIcon";
import { contestSettingsText } from "./helpers";

export default function ContestSettingsLibrary({
  createReqData,
  addReqData,
  fetchData,
  isWizard = false,
}) {
  return (
    <Flex gap="xs">
      {!isWizard && (
        <ContestSettingsCreate reqData={createReqData} onSuccess={fetchData} />
      )}
      <ContestSettingsSelect reqData={addReqData} onSuccess={fetchData} />
    </Flex>
  );
}

function ContestSettingsSelect({ onSuccess, reqData }) {
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setLoading(false);
    setOpen(false);
    setSearchValue("");
    setItems([]);
  }

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!isOpen) return;
    fetchItems();
  }, [searchValue, isOpen]);

  function fetchItems() {
    const req = {
      search: searchValue,
      page_size: 1000,
      page: 0,
    };

    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(`/retrieve-contest-settings-templates/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        size="xs"
        radius="xl"
        variant="light"
        leftSection={entityIcon.contest(16)}
        color="gray"
      >
        Contest Settings Library
      </Button>
      <Modal size="lg" onClose={onClose} opened={isOpen}>
        <div style={{ position: "relative" }}>
          <LoadingOverlay visible={loading} />
          <TextInput
            mb="sm"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {items.map((item, i) => (
            <div key={i}>
              <OptionItem
                id={item.id}
                item={item}
                onSuccess={() => {
                  onClose();
                  onSuccess();
                }}
                fetchData={fetchItems}
                reqData={reqData}
                setModalLoading={(c) => setLoading(c)}
              />
              {i !== items.length - 1 && <Divider mt="lg" mb="lg" />}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

const OptionItem = ({
  id,
  item,
  fetchData,
  onSuccess,
  reqData,
  setModalLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [adding, setAdding] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (!expanded) return;

    fetchItem();
  }, [expanded]);

  function fetchItem() {
    setLoading(true);

    axios
      .get(`/contest-settings-templates/${id}/template-data/`)
      .then(({ data }) => {
        setLoading(false);
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onAddClick() {
    const req = {
      ...reqData,
      contest_settings_template_id: item.id,
    };

    setModalLoading(true);

    axios
      .post(`/apply-contest-settings-template/`, req)
      .then(() => {
        toast.success("Added!");
        setModalLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setModalLoading(false);
        toast.error(err);
      });
  }

  function onSaveClick(formData) {
    const req = {
      ...formData,
      contest_settings_template_id: item.id,
    };
    setSaving(true);

    axios
      .put(`/contest-settings-templates/${item.id}/meta-data/`, req)
      .then(() => {
        toast.success("Added!");
        setSaving(false);
        setEditing(false);
        fetchData();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  const config = info && JSON.stringify(info) !== "{}" ? info : null;

  const sections = config
    ? [
        {
          title: "General",
          items: [
            {
              label: contestSettingsText.total_entries_allowed,
              value: config.total_entries_allowed,
            },
          ],
        },
        {
          title: "Initial Entry",
          items: [
            {
              label: contestSettingsText.initial_entry,
              value: config.initial_entry.entry_count,
            },
          ],
        },
        {
          title: "Keywords",
          items: config.keywords
            ? [
                {
                  label: contestSettingsText.keyword_use_total,
                  value: config.keywords.keyword_use_total || "",
                },
                {
                  label: contestSettingsText.keyword_value,
                  value: config.keywords.keyword_value || "",
                },
                {
                  label: contestSettingsText.keyword_total_entries_allowed,
                  value: config.keywords.total_entries_allowed || "",
                },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Keyword Intervals",
          intervals: true,
          items: config.keywords.intervals,
        },
        {
          title: "Check-Ins",
          items: config.check_ins
            ? [
                {
                  label: contestSettingsText.check_in_value,
                  value: config.check_ins.check_in_value || "",
                },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Check-In Intervals",
          intervals: true,
          items: config.check_ins.intervals,
        },
      ].filter((f) => f.items.length)
    : [];

  return (
    <div key={item.id}>
      <Text fw={600}>{item.title}</Text>
      <Text size="sm">{item.description}</Text>
      {confirm && (
        <Box mt="sm">
          <Text size="sm">
            Applying this configuration will{" "}
            <b>replace the current contest settings</b>. Are you sure you want
            to do this?
          </Text>
          <Flex mt="sm" gap="xs">
            <Button
              size="xs"
              color="green"
              onClick={onAddClick}
              loading={adding}
              radius="xl"
            >
              I'm sure, apply it
            </Button>
            <Button
              size="xs"
              onClick={() => setConfirm(false)}
              color="gray"
              variant="light"
              radius="xl"
              disabled={adding}
            >
              Get me out of here
            </Button>
          </Flex>
        </Box>
      )}
      {!confirm && (
        <Flex mt="sm" mb="sm" gap="md" align="center">
          <Button
            onClick={() => setConfirm(true)}
            loading={adding}
            color="green"
            size="xs"
            radius="xl"
          >
            Apply these settings
          </Button>
          <Button
            variant="light"
            color="gray"
            onClick={() => {
              setExpanded(!expanded);
              setEditing(false);
            }}
            leftSection={<IconSettings size={20} />}
            size="xs"
            radius="xl"
          >
            View settings
          </Button>
          <Button
            // component={Link}
            // to={`/contest-settings/${id}`}
            onClick={() => setEditing(!editing)}
            variant="light"
            radius="xl"
            size="xs"
            color="gray"
            leftSection={<IconPencil size={16} />}
          >
            Edit
          </Button>
        </Flex>
      )}
      {!confirm && (
        <>
          {expanded && (
            <Box mt="lg">
              {!error ? (
                <>
                  {config && (
                    <>
                      {sections.map((section, i) => (
                        <Box key={i} mb={i !== sections.length - 1 ? "md" : 0}>
                          <Text size="sm" mb="xs" fw={600}>
                            {section.title}
                          </Text>
                          {section.intervals ? (
                            <IntervalDisplay items={section.items} />
                          ) : (
                            <Table striped>
                              <Table.Tbody>
                                {section.items.map((sectionItem, ii) => (
                                  <Table.Tr key={ii}>
                                    <Table.Td w={400}>
                                      {sectionItem.label}
                                    </Table.Td>
                                    <Table.Td>
                                      <Flex gap="xs">
                                        {!sectionItem.color &&
                                          !sectionItem.image &&
                                          !sectionItem.link && (
                                            <>{sectionItem.value}</>
                                          )}
                                        {sectionItem.color && (
                                          <>
                                            <ColorSwatch
                                              color={
                                                sectionItem.mantine
                                                  ? `var(--mantine-color-${sectionItem.value}-5)`
                                                  : sectionItem.value
                                              }
                                            />
                                            <span>({sectionItem.value})</span>
                                          </>
                                        )}
                                        {sectionItem.image && (
                                          <>
                                            <a
                                              href={sectionItem.value}
                                              target="_blank"
                                            >
                                              <StyledImage
                                                src={sectionItem.value}
                                                title={sectionItem.label}
                                              />
                                            </a>
                                          </>
                                        )}
                                        {sectionItem.link && (
                                          <Anchor
                                            href={sectionItem.url}
                                            target="_blank"
                                            size="sm"
                                          >
                                            {sectionItem.url}
                                          </Anchor>
                                        )}
                                      </Flex>
                                    </Table.Td>
                                  </Table.Tr>
                                ))}
                              </Table.Tbody>
                            </Table>
                          )}
                        </Box>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Text>{error}</Text>
              )}
            </Box>
          )}
          {editing && (
            <Box mt="lg">
              <ContestSettingsForm
                title={item.title}
                description={item.description}
                loading={saving}
                onSubmit={onSaveClick}
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const IntervalDisplay = ({ items }) => {
  return (
    <div>
      {items.map((item, i) => (
        <Box mb="sm" key={i}>
          <IntervalItem
            frequency={item.frequency}
            interval={item.interval}
            values={item.values}
          />
        </Box>
      ))}
    </div>
  );
};

const StyledImage = styled.img`
  height: 75px;
  width: 75px;
  object-fit: cover;
`;

export const ContestSettingsForm = ({
  title,
  description,
  onSubmit,
  loading,
}) => {
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    setFormValues({
      title,
      description,
    });
  }, []);

  return (
    <div>
      <TextInput
        label="Title"
        required
        value={formValues.title}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            title: e.target.value,
          })
        }
      />
      <TextInput
        label="Short Description"
        required
        value={formValues.description}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            description: e.target.value,
          })
        }
      />
      <Button
        onClick={() => onSubmit(formValues)}
        disabled={!formValues.title || !formValues.description}
        loading={loading}
        mt="sm"
        size="sm"
        fullWidth
      >
        Save
      </Button>
    </div>
  );
};

function ContestSettingsCreate({ reqData }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    if (loading) return;
    setOpen(false);
    setLoading(false);
  }

  function onSubmit(formValues) {
    const req = {
      ...reqData,
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/contest-settings-templates/`, req)
      .then(() => {
        toast.success("Created!");
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        size="xs"
        radius="xl"
        variant="light"
        leftSection={<IconDeviceFloppy size={16} />}
        color="gray"
      >
        Save to Contest Settings Library
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <ContestSettingsForm loading={loading} onSubmit={onSubmit} />
      </Modal>
    </div>
  );
}
