export const rideChallengePath = `${
  import.meta.env.VITE_DOMAIN
}/hd-ride-challenge`;

export const rideChallengeSettings = {
  rev_score: {
    keywords_engagement_multiplier: 1,
    readings_engagement_multiplier: 1,
  },
  ride_challenge_warnings: {
    max_daily_mileage: "",
    vin_user_limit: "",
  },
};
