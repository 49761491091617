import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Flex,
  Text,
  Box,
  Switch,
  Table,
  ActionIcon,
  rem,
  Loader,
  Divider,
  SegmentedControl,
} from "@mantine/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  IconNotes,
  IconCaretUpFilled,
  IconCaretDownFilled,
} from "@tabler/icons-react";

import { TicketEntryForm } from "@components/Ticket";
import { OdometerForm } from "@components/RideChallengeApp/VehicleVerificationView";
import { Notes } from "@components/shared";
import { UserVehicleDatapoints } from "./";

const UserVehicleItem = ({ vehicle, fetchData }) => {
  const [showDatapointAdd, setShowDatapointAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [datapoints, setDatapoints] = useState([]);
  const [filters, setFilters] = useState({
    variety: "odometer",
    status: "active",
  });
  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    if (!isOpen) return;
    fetchDatapoints();
  }, [isOpen, JSON.stringify(filters)]);

  function fetchDatapoints() {
    const req = {
      ...filters,
      user_vehicle_id: vehicle.id,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/user-vehicles/${vehicle.id}/retrieve-data-points/`, req)
      .then(({ data }) => {
        setDatapoints(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setDatapoints([]);
        setError(err);
        setLoading(false);
      });
  }

  const mostRecentReading = datapoints.length ? datapoints[0] : null;

  return (
    <div>
      <Flex gap="sm" align="center">
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Text fw={600} key={vehicle.id}>
            {vehicle.year} {vehicle.make} {vehicle.model}
            {vehicle.series ? ` ${vehicle.series}` : ""}
          </Text>
          <Text size="sm" c="dimmed">
            VIN: {vehicle.vin}
          </Text>
          <Text size="sm" c="dimmed">
            {vehicle.odometer_total} miles
          </Text>
          <Text size="sm" c="dimmed">
            added at {vehicle.added_at_location_id}
          </Text>
        </div>
        <div>
          <ActionIcon
            onClick={() => setOpen(!isOpen)}
            size="lg"
            variant="light"
            color="gray"
            radius="xl"
          >
            {isOpen ? (
              <IconCaretUpFilled style={{ width: rem(16), height: rem(16) }} />
            ) : (
              <IconCaretDownFilled
                style={{ width: rem(16), height: rem(16) }}
              />
            )}
          </ActionIcon>
        </div>
      </Flex>
      {isOpen && (
        <div
          style={{
            minHeight: "125px",
          }}
        >
          {error && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: "125px",
              }}
            >
              <Text c="red" fw={600}>
                {error}
              </Text>
            </div>
          )}
          {datapoints && (
            <>
              {!managerInfo && (
                <>
                  {mostRecentReading && (
                    <Box mt="lg" mb="xl">
                      <Text fw={600}>Most Recent Datapoint</Text>
                      <DatapointTable
                        datapoints={[mostRecentReading]}
                        fetchData={fetchDatapoints}
                        showStatus
                        showEdit
                      />
                    </Box>
                  )}
                  <Divider mt="lg" mb="lg" />
                </>
              )}
              <UserVehicleDatapoints
                datapoints={datapoints}
                fetchData={fetchDatapoints}
                vehicleId={vehicle.id}
                userId={vehicle.user_id}
                showStatus={filters.status === "all"}
              >
                <Box>
                  <SegmentedControl
                    size="xs"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        status: e,
                      })
                    }
                    value={filters.status}
                    data={[
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                      { value: "all", label: "All" },
                    ]}
                  />
                </Box>
              </UserVehicleDatapoints>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserVehicleItem;

const Datapoint = ({ info, showStatus = false, fetchData }) => {
  const [ticketOpen, setTicketOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  return (
    <>
      {showStatus && !managerInfo && (
        <Table.Td>
          <StatusToggle
            onSuccess={() => fetchData()}
            checked={info.is_active}
            reqData={{
              user_vehicle_data_point_id: info.id,
            }}
          />
        </Table.Td>
      )}
      <Table.Td>{new Date(info.created_at).toLocaleString()}</Table.Td>
      <Table.Td>Location Here</Table.Td>
      <Table.Td>Verifier Here</Table.Td>
      <Table.Td>{info.odometer_value} miles</Table.Td>
      {managerInfo && (
        <Table.Td>
          <Button
            onClick={() => setTicketOpen(true)}
            size="xs"
            color="red"
            variant="light"
          >
            Report a problem
          </Button>
          <Modal
            opened={ticketOpen}
            onClose={() => {
              setTicketOpen(false);
            }}
          >
            <TicketEntryForm
              isManager
              showTitle={false}
              issueLabel="What's the problem here?"
              reqData={{
                first_name: managerInfo.full_name.split(" ")[0],
                last_name: managerInfo.full_name.split(" ")[1],
                email: managerInfo.email,
                user_vehicle_id: info.user_vehicle_id,
                user_vehicle_data_point_id: info.id,
                phone: "",
              }}
            />
          </Modal>
        </Table.Td>
      )}
      {!managerInfo && (
        <Table.Td>
          <ActionIcon
            onClick={() => setNotesOpen(true)}
            size="sm"
            variant="light"
            color="gray"
            radius="xl"
          >
            <IconNotes style={{ width: rem(16), height: rem(16) }} />
          </ActionIcon>
          <Modal
            opened={notesOpen}
            onClose={() => {
              setNotesOpen(false);
            }}
          >
            <Notes
              additionalCreateData={{
                user_vehicle_data_point_id: info.id,
              }}
              additionalFetchData={{
                user_vehicle_data_point_id: info.id,
              }}
              closable={false}
            />
          </Modal>
        </Table.Td>
      )}
    </>
  );
};

const StatusToggle = ({ reqData, checked, onSuccess }) => {
  function onChange() {
    axios
      .post(`/user-vehicle-data-points/toggle-active/`, reqData)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return <Switch label="Active" checked={checked} onChange={onChange} />;
};

const DatapointForm = ({ onSuccess, vehicleId }) => {
  const initialFormValues = {
    variety: 1,
    user_vehicle_id: vehicleId,
    odometer_value: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    const req = {
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/user-vehicles/${formValues.user_vehicle_id}/add-data-point/`, req)
      .then(() => {
        setFormValues({
          ...formValues,
          odometer_value: "",
        });
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Flex
        justify="center"
        mt="sm"
        p="lg"
        style={{
          background: "var(--mantine-color-dark-5)",
          borderRadius: "10px",
        }}
      >
        <OdometerForm
          value={formValues.odometer_value}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              odometer_value: e,
            })
          }
        />
      </Flex>
      <Button
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={!formValues.user_vehicle_id || !formValues.odometer_value}
        mt="md"
      >
        Create
      </Button>
    </div>
  );
};

const DatapointTable = ({ datapoints, fetchData, showStatus }) => (
  <Table striped>
    <Table.Thead>
      <Table.Tr>
        {showStatus && <Table.Th w={125}>Status</Table.Th>}
        <Table.Th w={200}>Date</Table.Th>
        <Table.Th>Location</Table.Th>
        <Table.Th>Verifier</Table.Th>
        <Table.Th>Reading</Table.Th>
        <Table.Th>Notes</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
      {datapoints.map((datapoint) => {
        return (
          <Table.Tr key={datapoint.id}>
            <Datapoint
              info={datapoint}
              fetchData={fetchData}
              showStatus={showStatus}
            />
          </Table.Tr>
        );
      })}
    </Table.Tbody>
  </Table>
);
