import React, { useEffect, useState } from "react";
import { Badge, Button, Text } from "@mantine/core";
import axios from "axios";
import { IconTarget, IconCheck } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import EffortSlider from "./EffortSlider";
import ViewTitle from "./ViewTitle";
import ViewLoader from "./ViewLoader";
import { iconSize } from "./helpers";
import useAppStore from "./ride-challenge-store";

export default function EffortPicker() {
  const [efforts, setEfforts] = useState([]);
  const [noLocationEfforts, setNoLocationEfforts] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    colors,
    campaignId,
    coords,
    entityInfo,
    effortId,
    location,
    user,
    setEffort,
  } = useAppStore(
    (state) => ({
      campaignId: state.campaignId,
      coords: state.coords,
      entityInfo: state.entityInfo,
      colors: state.colors,
      effortId: state.effortId,
      location: state.location,
      user: state.user,
      setEffort: state.setEffort,
    }),
    shallow
  );

  useEffect(() => {
    fetchEfforts();
  }, []);

  function fetchEfforts() {
    if (location) {
      fetchLocationEfforts();
    } else {
      fetchCampaignEfforts();
    }
  }

  function fetchCampaignEfforts() {
    const req = {
      campaign_id: campaignId,
    };

    if (user) req.user_id = user.id;
    if (location) req.location_id = location.id;

    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/campaign-efforts/`, req)
      .then(({ data }) => {
        setEfforts(data.response);
        if (location) {
          setNoLocationEfforts(true);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setEfforts([]);
        setLoading(false);
      });
  }

  function fetchLocationEfforts() {
    const req = {
      campaign_id: campaignId,
      location_id: location.id,
    };

    if (effortId) req.campaign_effort_id = effortId;
    if (user) req.user_id = user.id;

    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/location-efforts/`, req)
      .then(({ data }) => {
        if (data.response.length === 0) {
          fetchCampaignEfforts();
        } else {
          if (!user && data.response.length === 1) {
            setEffort(data.response[0]);
          } else {
            setEfforts(data.response);
          }
        }
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setEfforts([]);
        setLoading(false);
      });
  }

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ViewTitle
        title={t("effort_picker_title")}
        subtitle={t("effort_picker_subtitle")}
        icon={<IconTarget size={iconSize} />}
      />
      {noLocationEfforts && location && (
        <Text align="center" mb="md" c="white">
          <b>{location.name}</b> {t("effort_picker_location_not_participating")}
        </Text>
      )}
      {loading && <ViewLoader />}
      <EffortSlider
        colors={colors}
        efforts={efforts
          .sort((a, b) => (a.entered > b.entered ? 1 : -1))
          .map((eff) => ({
            ...eff,
            children: user ? (
              <UserButton
                effortId={eff.id}
                // onSuccess={onUserSelect}
                onClick={() => setEffort(eff)}
                entered={eff.entered}
              />
            ) : (
              <Button
                style={{ marginTop: "1.5em" }}
                onClick={() => setEffort(eff)}
                disabled={!location}
              >
                {t("effort_picker_select_effort_button")}
              </Button>
            ),
          }))}
      />
    </React.Fragment>
  );
}

const UserButton = ({ onClick, entered = false, effortId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const user = useAppStore((state) => state.user);

  const userEffortIds = user.campaign_efforts
    ? user.campaign_efforts
        .filter((f) => f.entered)
        .map((m) => m.campaign_effort_id)
    : [];

  const { t } = useTranslation();

  if (!effortId) return null;

  if (userEffortIds.includes(effortId)) {
    return (
      <Badge variant="light" color="green" size="lg">
        {t("effort_already_registered")}
      </Badge>
    );
  }

  return (
    <Button
      style={{ marginTop: "1.5em" }}
      radius="md"
      onClick={onClick}
      leftSection={entered ? <IconCheck /> : null}
      disabled={loading || userEffortIds.includes(effortId) || entered}
      loading={loading}
    >
      {userEffortIds.includes(effortId) || entered
        ? t("effort_already_registered")
        : t("effort_register_button")}
    </Button>
  );
};
