import React from "react";
import { Box, Text, Flex, Card } from "@mantine/core";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

export default function UserHistory({ mileageItems = [], keywordItems = [] }) {
  return (
    <div>
      {mileageItems.length > 0 && (
        <div>
          <Text mb="lg" fw={600} c="white">
            Mileage History
          </Text>
          {mileageItems.map((mileageItem, mileageIterator) => (
            <motion.div
              key={mileageItem.id}
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={itemVariants}>
                <Card
                  mb={mileageIterator < mileageItems.length ? "md" : 0}
                  style={{
                    background: "var(--mantine-color-dark-8)",
                    borderRadius: "5px",
                    borderColor: "var(--mantine-color-dark-6)",
                  }}
                >
                  <div>
                    <Flex gap="xs" align="center">
                      <div>
                        {mileageItem.location_logo_url ? (
                          <StyledImage
                            height={50}
                            width={50}
                            src={mileageItem.location_logo_url}
                          />
                        ) : (
                          <StyledPlaceholder height={50} width={50} />
                        )}
                      </div>
                      <div
                        style={{
                          flexGrow: 1,
                        }}
                      >
                        <Text fw={600} c="white">
                          {mileageItem.location_name}
                        </Text>
                        {mileageItem.odometer_reading && (
                          <Text c="white" size="sm">
                            {mileageItem.odometer_reading.odometer_value} miles
                            odometer reading
                          </Text>
                        )}
                      </div>
                      {mileageItem.odometer_reading && (
                        <Text c="dimmed" size="sm">
                          {new Date(
                            mileageItem.odometer_reading.created_at
                          ).toLocaleDateString()}
                        </Text>
                      )}
                    </Flex>
                    {mileageItem.user_vehicle && (
                      <Box mt="xs">
                        <Text
                          style={{ maxWidth: "80%" }}
                          align="left"
                          size="xs"
                          c="dimmed"
                        >
                          {mileageItem.user_vehicle.year}{" "}
                          {mileageItem.user_vehicle.make}{" "}
                          {mileageItem.user_vehicle.model}
                          {mileageItem.user_vehicle.series &&
                            ` ${mileageItem.user_vehicle.series}`}
                        </Text>
                      </Box>
                    )}
                  </div>
                </Card>
              </motion.div>
            </motion.div>
          ))}
        </div>
      )}
      {keywordItems.length > 0 && (
        <div>
          <Text mb="lg" fw={600} c="white">
            Keyword History
          </Text>
          {keywordItems.map((keywordItem, keywordIterator) => (
            <motion.div
              key={keywordItem.id}
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={itemVariants}>
                <Card
                  mb={keywordIterator < keywordItems.length ? "md" : 0}
                  style={{
                    background: "var(--mantine-color-dark-8)",
                    borderRadius: "5px",
                    borderColor: "var(--mantine-color-dark-6)",
                  }}
                >
                  <div>
                    <Flex gap="xs" align="center">
                      <div>
                        {keywordItem.location_logo_url ? (
                          <StyledImage
                            height={50}
                            width={50}
                            src={keywordItem.location_logo_url}
                          />
                        ) : (
                          <StyledPlaceholder height={50} width={50} />
                        )}
                      </div>
                      <div
                        style={{
                          flexGrow: 1,
                        }}
                      >
                        <Text fw={600} c="white">
                          {keywordItem.location_name}
                        </Text>
                        {keywordItem.keyword_text && (
                          <Text c="white" size="sm">
                            claimed <b>{keywordItem.keyword_text}</b> keyword
                          </Text>
                        )}
                      </div>
                      <Text c="dimmed" size="sm">
                        {new Date(keywordItem.claimed_at).toLocaleDateString()}
                      </Text>
                    </Flex>
                  </div>
                </Card>
              </motion.div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};
