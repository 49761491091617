import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  Modal,
  Group,
  Text,
  Flex,
  TextInput,
  SegmentedControl,
  Title,
  ActionIcon,
  Badge,
} from "@mantine/core";
import { IconCircle, IconMedal, IconPencil } from "@tabler/icons-react";
import styled from "styled-components";

import { MilestoneItem } from "./";
import { PaginatedList } from "@components/shared";

export default function MilestoneList({
  items = null,
  requestInfo = {
    url: `/retrieve-milestones/`,
    data: {},
  },
  refresh,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [refresh2, setRefresh2] = useState(false);
  const [filters, setFilters] = useState({
    scope: "entire",
    status: "entire",
  });

  const reqData = {
    ...filters,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
    pagination: true,
  };

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mb="sm"
          />
          <Flex gap="xs">
            <SegmentedControl
              value={filters.status}
              data={[
                { label: "All", value: "entire" },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e,
                })
              }
            />
            <SegmentedControl
              value={filters.scope}
              data={[
                { label: "All", value: "entire" },
                { label: "Current", value: "current" },
                { label: "Pickup", value: "requires_pickup" },
                { label: "Ongoing", value: "ongoing" },
                { label: "Virtual", value: "virtual" },
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  scope: e,
                })
              }
            />
          </Flex>
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        columns={2}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/milestones/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            // component={Link}
            // to={item.original.link_url}
            key={item.original.id}
          >
            <Flex gap="md">
              <div>
                {item.original.filename_url ? (
                  <StyledImage
                    src={item.original.filename_url}
                    height={100}
                    width={100}
                  />
                ) : (
                  <StyledPlaceholder height={100} width={100}>
                    <IconMedal size={24} color="var(--mantine-color-gray-6)" />
                  </StyledPlaceholder>
                )}
              </div>
              <div style={{ flexGrow: 1 }}>
                <Flex align="center" gap="xs">
                  <Text fw={700}>{item.original.title}</Text>
                  {item.original.status && (
                    <IconCircle
                      size={16}
                      color={
                        item.original.status === 1
                          ? "var(--mantine-color-green-5)"
                          : "var(--mantine-color-red-5)"
                      }
                      fill={
                        item.original.status === 1
                          ? "var(--mantine-color-green-5)"
                          : "var(--mantine-color-red-5)"
                      }
                    />
                  )}
                  <MilestoneEdit
                    info={item.original}
                    id={item.original.id}
                    onSuccess={() => setRefresh2(!refresh2)}
                  />
                </Flex>
                <Text size="sm" c="dimmed">
                  {item.original.description}
                </Text>
                {item.original.start_at && (
                  <Group gap="xs">
                    {/* <div>{entityIcon.campaign()}</div> */}
                    <Text size="sm" c="dimmed">
                      {new Date(item.original.start_at).toLocaleDateString()} -{" "}
                      {new Date(item.original.end_at).toLocaleDateString()}
                    </Text>
                  </Group>
                )}
                <Box>
                  <Badge
                    size="xs"
                    variant="light"
                    color={item.original.requires_pickup ? "orange" : "blue"}
                  >
                    {item.original.requires_pickup
                      ? "Requires pickup"
                      : "Virtual"}
                  </Badge>
                </Box>
              </div>
              <div>
                <Title order={2} align="center" fw={600}>
                  {item.original.value}
                </Title>
                <Text align="center" size="xs" c="dimmed">
                  miles
                </Text>
              </div>
            </Flex>
          </Card>
        )}
      />
    </div>
  );
}

const MilestoneEdit = ({ info, id, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);
  if (!info) return null;

  function onClose() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <ActionIcon
        color="gray"
        size="xs"
        variant="light"
        radius="xl"
        onClick={() => setOpen(true)}
      >
        <IconPencil size={14} />
      </ActionIcon>
      <Modal opened={isOpen} onClose={onClose}>
        <MilestoneItem info={info} onSuccess={onClose} id={id} />
      </Modal>
    </div>
  );
};

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;
