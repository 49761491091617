import React, { useState, useEffect } from "react";
import { Button, Box, Flex, Text, Title } from "@mantine/core";
import axios from "axios";
import styled from "styled-components";

import UserHistory from "./UserHistory";
import {
  Odometer,
  RideChallengeRevScoreGauge,
} from "@components/RideChallenge";

export default function RevScore({
  effortData,
  onRevScoreClick,
  expanded = false,
  reqData,
  setAppLoading = () => true,
  tiers = [],
  revScore,
}) {
  if (!effortData) return null;
  const [revScoreInfo, setRevScoreInfo] = useState(null);

  useEffect(() => {
    fetchData();
  }, [expanded, JSON.stringify(effortData)]);

  function fetchData() {
    setAppLoading(true);
    axios
      .post(`/ride-challenge/rev-breakdown/`, reqData)
      .then(({ data }) => {
        setRevScoreInfo(data.response[0]);
        setAppLoading(false);
      })
      .catch((err) => {
        setRevScoreInfo(null);
        setAppLoading(false);
      });
  }

  const stats = revScoreInfo
    ? [
        {
          text: "total_miles",
          value: revScoreInfo.total_miles,
        },
        {
          text: "rev_score_keywords_multiplier",
          value: revScoreInfo.rev_score_keywords_multiplier,
        },
        {
          text: "keyword_engagements_count",
          value: revScoreInfo.keyword_engagements_count,
        },
        {
          text: "keyword_engagements_rev",
          value: revScoreInfo.keyword_engagements_rev,
        },
        {
          text: "rev_score_readings_multiplier",
          value: revScoreInfo.rev_score_readings_multiplier,
        },
        {
          text: "readings_engagements_count",
          value: revScoreInfo.readings_engagements_count,
        },
        {
          text: "readings_engagements_rev",
          value: revScoreInfo.readings_engagements_rev,
        },
      ]
    : [];

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <StyledOdometer>
            <Odometer
              newValue={
                revScoreInfo ? revScoreInfo.total_miles : effortData.total_miles
              }
            />
            <Text c="white" fw={600}>
              MILES
            </Text>
          </StyledOdometer>
          {tiers.length > 0 && (
            <div
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                width: "375px",
                position: "relative",
                maxWidth: "375px",
              }}
            >
              <RideChallengeRevScoreGauge
                tiers={tiers}
                value={
                  revScoreInfo
                    ? revScoreInfo.total_miles
                    : effortData.total_miles
                }
              />
            </div>
          )}
        </div>
      </div>
      <StyledRevScoreCircle
        revScoreLength={
          revScoreInfo
            ? `${revScoreInfo.rev_score}`.length
            : `${revScore}`.length
        }
      >
        <p>
          <span>REV SCORE</span>
        </p>
        <h1>{revScoreInfo ? revScoreInfo.rev_score : revScore}</h1>
      </StyledRevScoreCircle>
      {/* <Flex justify="center" direction="column" align="center">
        <Text size="xs" c="dimmed">
          miles logged
        </Text>
      </Flex> */}
      {!expanded && (
        <div
          style={{
            position: "relative",
            top: "-20px",
          }}
        >
          {/* <Text mt="lg" align="center" c="white" fw={600} size="xl">
            Rev Score: {effortData.rev_score}
          </Text> */}
          <Flex justify="center">
            <Button size="xs" variant="subtle" onClick={onRevScoreClick}>
              how is this calculated?
            </Button>
          </Flex>
        </div>
      )}
      {expanded && (
        <div style={{ minHeight: "500px" }}>
          {revScoreInfo && (
            <div>
              {/* <Title mt="lg" c="white" align="center" mb="lg" order={1}>
                Rev Score: {revScoreInfo.rev_score}
              </Title> */}
              <Flex gap="xs" justify="center">
                <Text c="white" align="center">
                  {revScoreInfo.total_miles}
                  <br />
                  Total Miles
                </Text>
                <Text c="white" align="center">
                  +
                </Text>
                <Text c="white" align="center">
                  {revScoreInfo.readings_engagements_rev}
                  <br />
                  Odometer Rev
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    ({revScoreInfo.readings_engagements_count} reading *{" "}
                    {revScoreInfo.rev_score_readings_multiplier})
                  </span>
                </Text>
                <Text c="white" align="center">
                  +
                </Text>
                <Text c="white" align="center">
                  {revScoreInfo.keyword_engagements_rev}
                  <br />
                  Keywords Rev
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    ({revScoreInfo.keyword_engagements_count} claim *{" "}
                    {revScoreInfo.rev_score_keywords_multiplier})
                  </span>
                </Text>
              </Flex>
              {/* <Box mb="lg" mt="lg">
                {stats.map((stat, i) => (
                  <Text key={i} c="white" size="xs">
                    {stat.text}: {stat.value}
                  </Text>
                ))}
              </Box> */}
              <Box mt="xl">
                <UserHistory
                  mileageItems={revScoreInfo.mileage}
                  keywordItems={revScoreInfo.keywords}
                />
              </Box>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const StyledOdometer = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .odometer-value {
    /* font-size: 40px; */
  }
`;

const StyledRevScoreCircle = styled.div`
  height: 125px;
  width: 125px;
  background: var(--mantine-color-orange-5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  top: -50px;

  p {
    color: #fff;
    line-height: 0;
    font-size: 0.85em;
    font-weight: 600;
  }

  h1 {
    line-height: 0.7;
    margin: 5px 0 0 0;
    color: #fff;
    /* font-size: 2.1em; */
    font-size: ${(props) => (props.revScoreLength >= 6 ? "1.5em" : "2.1em")};
  }
`;
